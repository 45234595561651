import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from '@ionic/react'
import { ReactNode } from 'react'

const Header: React.FC<{
  title: string
  children?: ReactNode
  hasBackButton?: boolean
  backUrl?: string
}> = ({ title, children, hasBackButton, backUrl }) => {
  return (
    <IonHeader>
      <IonToolbar color='primary'>
        {hasBackButton ? (
          <IonButtons slot='start'>
            <IonBackButton defaultHref={backUrl} text='' color='light' />
          </IonButtons>
        ) : (
          <IonButtons slot='start'>
            <IonMenuButton color='light' />
          </IonButtons>
        )}
        <IonTitle color='light' className='ion-padding ion-text-center'>
          {title}
        </IonTitle>
      </IonToolbar>
      {children}
    </IonHeader>
  )
}

export default Header
