import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import StoringEntryAPI from '../../../api/storingEntryAPI'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import { IStoringEntry } from '../model/storingEntry'
import StoringEntryView from './StoringEntryView'

const StoringEntry = observer(() => {
  const { storingEntryStore, currentShiftStore } = useContext(AppContext)
  const notify = useNotify()
  const history = useHistory()
  const {
    storingEntries,
    isLoading,
    setIsLoading,
    setStoringEntries,
  } = storingEntryStore
  const { currentShift } = currentShiftStore

  useEffect(() => {
    if (!currentShift) {
      notify.info('Bạn cần đăng nhập ca trực để load dữ liệu')
      setStoringEntries([])
    }
  }, [currentShift])

  useEffect(() => {
    let isFetch = true
    if (currentShift && isFetch) {
      const getProductLocation = async () => {
        setIsLoading(true)

        let params = {}
        const { shift, outlet } = currentShift
        params = { shift, outlet: outlet._id || outlet }
        const storingEntries: IStoringEntry[] = await StoringEntryAPI.getAll(
          params
        )
          .catch(notify.errorFromServer)
          .finally(() => {
            setIsLoading(false)
          })
        setStoringEntries(storingEntries)
      }
      getProductLocation()
    }
    return () => {
      isFetch = false
    }
  }, [currentShift])

  return (
    <StoringEntryView storingEntries={storingEntries} isLoading={isLoading} />
  )
})

export default StoringEntry
