import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import { list, pricetag, reader, informationCircle } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../context'
import ItemDetail from '../features/Item/page/ItemDetail'
import RestockReportDetail from '../features/RestockReport/page/RestockReportDetail'
import RestockReportsView from '../features/RestockReport/page/RestockReportsView'
import RestockReportInfo from '../features/RestockReport/page/RestockReportInfo'
import PrivateRoute from './PrivateRoute'
import { IonReactRouter } from '@ionic/react-router'

export const TabMenu = observer(() => {
  const { itemStore, restockReportStore } = useContext(AppContext)
  const { item } = itemStore
  const { restockReport } = restockReportStore
  const productId = item?.product?._id
  const restockReportId = restockReport?._id
  const location = useLocation()
  let currentRoute = location.pathname
  let isRestockReportList = !!currentRoute.match(/restock-reports$/)
  let isRestockReportDetail = !!currentRoute.includes('detail')
  let isItemDetail = !!currentRoute.includes('view')
  let isRestockReportInfo = !!currentRoute.includes('info')

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet animated={false}>
          <PrivateRoute
            path='/restock-reports'
            exact
            component={RestockReportsView}
          />
          <PrivateRoute
            path='/restock-reports/detail/:restockReportId'
            exact
            component={RestockReportDetail}
          />
          <PrivateRoute
            path='/restock-reports/:restockReportId/info'
            exact
            component={RestockReportInfo}
          />
          <PrivateRoute
            path='/restock-reports/:restockReportId/view/:productId'
            component={ItemDetail}
            exact={true}
          />
        </IonRouterOutlet>
        <IonTabBar
          color='dark'
          slot='bottom'
          style={{
            borderRadius: '25px 25px 0px 0px',
            padding: '10px',
          }}
        >
          <IonTabButton
            tab='restock-reports'
            href={'/restock-reports'}
            selected={isRestockReportList}
          >
            <IonIcon icon={list} />
            <IonLabel>Restocks</IonLabel>
          </IonTabButton>

          <IonTabButton
            disabled={!restockReportId}
            tab='list-items'
            href={`/restock-reports/detail/${restockReportId}`}
            selected={isRestockReportDetail}
          >
            <IonIcon icon={reader} />
            <IonLabel>List</IonLabel>
          </IonTabButton>

          <IonTabButton
            disabled={!restockReportId || !productId}
            tab='item-detail'
            href={`/restock-reports/${restockReportId}/view/${productId}`}
            selected={isItemDetail}
          >
            <IonIcon icon={pricetag} />
            <IonLabel>SKU</IonLabel>
          </IonTabButton>

          <IonTabButton
            disabled={!restockReportId}
            tab='restock-report-info'
            href={`/restock-reports/${restockReportId}/info`}
            selected={isRestockReportInfo}
          >
            <IonIcon icon={informationCircle} />
            <IonLabel>Overview</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  )
})
