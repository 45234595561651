import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'
import {
  calendar,
  cart,
  chatboxEllipses,
  chevronDown,
  chevronForward,
  home,
  location as locationIcon,
  logIn,
  logOut,
  pricetag,
  reader,
} from 'ionicons/icons'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../context'
import useNotify from '../hook/useNotify'
import './Menu.css'
const database = '/assets/icon/database.svg'
const storing = '/assets/icon/open-box.svg'
const buying = '/assets/icon/buy.svg'
const bill = '/assets/icon/bill.svg'
interface AppPage {
  id: string
  url?: string
  iosIcon: string
  mdIcon: string
  title: string
  childrens?: AppPage[]
  hidden?: boolean
  onClick?: () => any
}

type SelectMenu = {
  name: string
  toggle: boolean
}

const Menu = observer(() => {
  const { currentShiftStore, authenticationStore } = useContext(AppContext)
  const { user } = authenticationStore
  const { currentShift, removeCurrentShift } = currentShiftStore
  const notify = useNotify()
  const appPages = useMemo<AppPage[]>(
    () => [
      {
        id: 'home',
        title: 'Home',
        url: '/home',
        iosIcon: home,
        mdIcon: home,
      },
      {
        id: 'current-shift',
        title: currentShift?.shiftRecordId || 'Current Shift',
        iosIcon: calendar,
        mdIcon: calendar,
        childrens: [
          {
            id: 'choose-current-shift',
            url: '/choose-current-shift',
            iosIcon: logIn,
            mdIcon: logIn,
            title: 'Choose Current Shift',
            hidden: !!currentShift?.shiftRecordId,
          },
          {
            id: 'exit-current-shift',
            iosIcon: logOut,
            mdIcon: logOut,
            title: 'Exit Current Shift',
            hidden: !currentShift?.shiftRecordId,
            onClick: () => {
              notify.success('Thoát ca trực thành công')
              removeCurrentShift()
            },
          },
        ],
      },
      {
        id: 'logistics',
        title: 'Logistics',
        iosIcon: cart,
        mdIcon: cart,
        childrens: [
          {
            id: 'restock-reports',
            url: '/restock-reports',
            iosIcon: reader,
            mdIcon: reader,
            title: 'Restocks',
          },
        ],
      },
      {
        id: 'storing',
        title: 'Storing',
        iosIcon: database,
        mdIcon: database,
        childrens: [
          {
            id: 'product-locations',
            url: '/storing/product-locations',
            iosIcon: locationIcon,
            mdIcon: locationIcon,
            title: 'Product Locations',
          },
          {
            id: 'confirm-pl-tasks',
            url: '/storing/confirm-pl-tasks',
            iosIcon: pricetag,
            mdIcon: pricetag,
            title: 'Confirm PL Tasks',
          },
          {
            id: 'storing-entries',
            url: '/storing/storing-entries',
            iosIcon: storing,
            mdIcon: storing,
            title: 'Storing Entries',
          },
        ],
      },
      {
        id: 'buying',
        title: 'Buying',
        iosIcon: buying,
        mdIcon: buying,
        childrens: [
          {
            id: 'confirm-buying-entries',
            url: '/buying-entries/confirm-buying-entries',
            iosIcon: bill,
            mdIcon: bill,
            title: 'Confirm Buying Entries',
          },
        ],
      },
      {
        id: 'feedback',
        title: 'Feedback',
        url: '/feedback',
        iosIcon: chatboxEllipses,
        mdIcon: chatboxEllipses,
      },

      !!user
        ? {
            id: 'logout',
            title: 'Logout',
            url: '/logout',
            iosIcon: logOut,
            mdIcon: logOut,
          }
        : {
            id: 'login',
            title: 'Login',
            url: '/login',
            iosIcon: logIn,
            mdIcon: logIn,
          },
    ],
    [currentShift?.shiftRecordId, user]
  )

  const [selectedMenu, setSelectedMenu] = useState<SelectMenu>({
    name: '',
    toggle: false,
  })
  const location = useLocation()

  const handleMenuClick = (appPage: AppPage): void => {
    setSelectedMenu((currentSelectedMenu) => {
      const cloneSelectedMenu = { ...currentSelectedMenu }
      if (appPage.id === cloneSelectedMenu.name) {
        cloneSelectedMenu.toggle = !cloneSelectedMenu.toggle
      } else {
        cloneSelectedMenu.name = appPage.id
        cloneSelectedMenu.toggle = true
      }
      return cloneSelectedMenu
    })
  }

  const isToggleMenu = (appPage: AppPage): boolean => {
    if (appPage.id !== selectedMenu.name) {
      return false
    }
    return selectedMenu.toggle
  }

  return (
    <IonMenu contentId='main' type='overlay'>
      <IonContent>
        <IonList id='inbox-list'>
          <IonListHeader className='border-bottom'>
            {!isEmpty(user) ? user?.displayName : 'Không xác định'}
          </IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <React.Fragment key={index}>
                {!appPage.hidden && appPage.url && (
                  <IonMenuToggle autoHide={false}>
                    <IonItem
                      className={
                        location.pathname.includes(appPage.url)
                          ? 'selected'
                          : ''
                      }
                      onClick={() => handleMenuClick(appPage)}
                      routerLink={appPage?.url}
                      routerDirection='none'
                      lines='none'
                      detail={!!appPage.childrens?.length}
                    >
                      <IonIcon
                        slot='start'
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                )}
                {!appPage.hidden && !appPage.url && (
                  <IonItem
                    className={
                      location.pathname === appPage.url ? 'selected' : ''
                    }
                    onClick={() => handleMenuClick(appPage)}
                    routerLink={appPage?.url}
                    routerDirection='none'
                    lines='none'
                    detail={!!appPage.childrens?.length}
                    detailIcon={
                      isToggleMenu(appPage) ? chevronDown : chevronForward
                    }
                  >
                    <IonIcon
                      slot='start'
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                )}
                {isToggleMenu(appPage) && (
                  <>
                    {appPage.childrens?.map((children, index) => {
                      return !children.hidden ? (
                        <IonMenuToggle key={index}>
                          <IonItem
                            className={
                              location.pathname === children.url
                                ? 'selected'
                                : ''
                            }
                            style={{ marginLeft: '20px' }}
                            routerLink={children.url}
                            routerDirection='none'
                            detail={false}
                            detailIcon={
                              isToggleMenu(appPage)
                                ? chevronDown
                                : chevronForward
                            }
                            onClick={children.onClick}
                          >
                            <IonIcon
                              slot='start'
                              ios={children.iosIcon}
                              md={children.mdIcon}
                            />
                            <IonLabel>{children.title}</IonLabel>
                          </IonItem>
                        </IonMenuToggle>
                      ) : null
                    })}
                  </>
                )}
              </React.Fragment>
            )
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  )
})

export default Menu
