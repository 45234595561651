import { yupResolver } from '@hookform/resolvers/yup'
import {
  IonButton,
  IonContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import { filter, get, map } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import Header from '../../../components/Header'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import apiClient from '../../../libs/api-service'

type FormValues = {
  outlet: string
  shift: string
  date: string
}

let schema = yup.object().shape({
  outlet: yup.string().required('Please choose an outlet'),
  shift: yup.string().required('Please choose a shift'),
  date: yup.string().required('Please choose a date'),
})

export interface IOutlet {
  _id: string
  name: string
  code: string
  business: {
    code: string
  }
  isWarehouse: boolean
}

const ChooseCurrentShift = observer(() => {
  const history = useHistory()

  const { currentShiftStore, buyingEntryStore, plTaskStore } = useContext(
    AppContext
  )
  const { setCurrentShift } = currentShiftStore

  const [outlets, setOutlets] = useState<IOutlet[]>([])

  const notify = useNotify()

  const getOutlets = async () => {
    const outlets: IOutlet[] = await apiClient.get('outlets')
    const warehouseOutlets = filter(outlets, (outlet) => {
      return (
        get(outlet, 'isWarehouse') === true &&
        get(outlet, 'business.code') === 'ABBY'
      )
    })

    setOutlets(warehouseOutlets)
  }

  useEffect(() => {
    let subcribe = true
    if (subcribe) {
      getOutlets()
    }
    return () => {
      subcribe = false
    }
  }, [])

  const { register, handleSubmit, errors } = useForm<FormValues>({
    defaultValues: {
      outlet: '',
      shift: '',
      date: moment().toISOString(),
    },
    resolver: yupResolver(schema),
  })

  const onCancel = () => {
    history.goBack()
  }

  const onChooseCurrentShift: SubmitHandler<FormValues> = async ({
    outlet,
    shift,
    date,
  }) => {
    try {
      const shiftRecord = await apiClient.post('/api/shift-records', {
        outlet,
        shift,
        date,
      })
      notify.success('Đăng nhập ca trực thành công !')
      setCurrentShift(shiftRecord)
      buyingEntryStore.setReload(true)
      plTaskStore.setReload(true)
      history.goBack()
    } catch (error) {
      return notify.errorFromServer(error)
    }
  }

  return (
    <IonPage>
      <Header title='Choose Current Shift' />
      <IonContent>
        <form
          style={{ padding: 15 }}
          onSubmit={handleSubmit(onChooseCurrentShift)}
        >
          <IonItem>
            <IonLabel position='floating'>Outlet</IonLabel>
            <IonSelect name='outlet' ref={register}>
              {map(outlets, (outlet) => (
                <IonSelectOption key={outlet._id} value={outlet._id}>
                  {outlet.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            {errors && errors['outlet'] && (
              <IonText color='danger' className='ion-padding-start'>
                <small>{errors['outlet'].message}</small>
              </IonText>
            )}
          </IonItem>

          <IonItem>
            <IonLabel position='floating'>Ngày</IonLabel>
            <IonDatetime
              name='date'
              ref={register}
              displayFormat='DD/MM/YYYY'
            />
            {errors && errors['date'] && (
              <IonText color='danger' className='ion-padding-start'>
                <small>{errors['date'].message}</small>
              </IonText>
            )}
          </IonItem>

          <IonItem>
            <IonLabel position='floating'>Ca trực</IonLabel>
            <IonSelect name='shift' ref={register}>
              <IonSelectOption value='S'>Sáng</IonSelectOption>
              <IonSelectOption value='C'>Chiều</IonSelectOption>
              <IonSelectOption value='T'>Tối</IonSelectOption>
            </IonSelect>
            {errors && errors['shift'] && (
              <IonText color='danger' className='ion-padding-start'>
                <small>{errors['shift'].message}</small>
              </IonText>
            )}
          </IonItem>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 10,
            }}
          >
            <IonButton onClick={onCancel} color='medium'>
              Back
            </IonButton>
            <IonButton type='submit'>Confirm</IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  )
})

export default ChooseCurrentShift
