import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { images as iconImages } from 'ionicons/icons'
import { map } from 'lodash'
import { useRef, Dispatch, SetStateAction } from 'react'
import axios from 'axios'
import ENV from '../env/env'

import './UploadImageButton.css'
import { IImageUpload } from '../pages/FeedbackView'

interface Props {
  setImagesUpload: Dispatch<SetStateAction<IImageUpload[]>>
}

const UploadImageButton = ({ setImagesUpload }: Props) => {
  const inputFileRef = useRef<HTMLInputElement>(null)

  const triggerInputFile = () => {
    inputFileRef.current?.click()
  }

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const _images = []

    if (event.target.files && event.target.files.length) {
      const length = event.target.files.length
      for (let i = 0; i < length; i++) {
        const image = { file: event.target.files[i] }
        _images.push(image)
      }

      const uploadedImages = await upload(_images)
      setImagesUpload((current) => [...current, ...uploadedImages])
    }
  }

  const upload = async (images: any): Promise<IImageUpload[]> => {
    return Promise.all(
      map(images, async (image) => {
        const imageBase64 = (await convertBase64(image.file)) as string

        if (imageBase64) {
          try {
            const result = await axios.post(
              `${ENV.IMAGE_WORKER_URL}/resize-and-upload`,
              { data: imageBase64 },
              {
                headers: {
                  'content-type': 'application/json',
                },
              }
            )

            const uploadedImage = result.data.uploadedImage[0]
            return uploadedImage
          } catch (error) {}
        }
      })
    )
  }

  const convertBase64 = (file: any): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <input
        type='file'
        accept='image/*;capture=camera'
        name='image'
        id='image'
        multiple={true}
        onChange={onImageChange}
        ref={inputFileRef}
        hidden
      />
      <IonButton
        className='button-add-image'
        fill='outline'
        onClick={triggerInputFile}
      >
        <IonIcon className='button-add-image--icon' icon={iconImages} />
      </IonButton>
    </div>
  )
}

export default UploadImageButton
