import { IonContent, IonLoading, IonPage } from '@ionic/react'
import { filter, reduce, split, take } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchBar from '../../../components/SearchBar'
import useNotify from '../../../hook/useNotify'
import StoringEntryList from '../components/StoringEntryList'
import { IStoringEntry } from '../model/storingEntry'

type TProps = {
  storingEntries: IStoringEntry[]
  isLoading: boolean
}

const StoringEntryView = observer(({ storingEntries, isLoading }: TProps) => {
  const [storingEntriesFilterd, setStoringEntriesFiltered] = useState<
    IStoringEntry[]
  >(() => storingEntries)
  const notify = useNotify()
  useEffect(() => {
    notify.info('Bạn có thể tìm kiếm theo SKU hoặc mô tả sản phẩm')
  }, [])

  useEffect(() => {
    setStoringEntriesFiltered(storingEntries)
  }, [storingEntries])

  const onSubmitSearch = (e: any, textSearch: string) => {
    e.preventDefault()

    if (textSearch === '') {
      setStoringEntriesFiltered(storingEntries)
      return
    }

    const keywords = split(textSearch, ' ')

    let cond = reduce(
      keywords,
      (result, keywork) => {
        return result + `(?=${keywork}).*`
      },
      ''
    )

    const regexSearch = new RegExp(cond, 'i')

    const filtered: IStoringEntry[] = filter(storingEntries, (storingEntry) => {
      const fullDescription =
        storingEntry.sku +
        ' ' +
        storingEntry.description +
        ' ' +
        storingEntry.storingEntryId
      return regexSearch.test(fullDescription)
    })
    setStoringEntriesFiltered(filtered)
  }

  return (
    <IonPage>
      <Header title='Storing Entries' />
      <IonContent>
        <SearchBar debounce={500} onSubmit={onSubmitSearch} />
        <StoringEntryList storingEntries={storingEntriesFilterd} />
      </IonContent>
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
})

export default StoringEntryView
