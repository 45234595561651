import { IonList } from '@ionic/react'
import { map } from 'lodash'
import { useContext, useState } from 'react'
import StoringEntryAPI from '../../../api/storingEntryAPI'
import ModalSearchProductLocations from '../../../components/SearchProductLocation'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import { IStoringEntry } from '../model/storingEntry'
import StoringEntryItem from './StoringEntryItem'

type TProps = {
  storingEntries: IStoringEntry[]
}

const StoringEntryList = ({ storingEntries }: TProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const notify = useNotify()
  const [
    currentStoringEntry,
    setCurrentStoringEntry,
  ] = useState<IStoringEntry>()

  const { storingEntryStore } = useContext(AppContext)

  const { setIsLoading, updateStoringLocation } = storingEntryStore

  const onDismissModal = () => {
    setIsLoading(false)
    setIsShowModal(false)
  }

  const onChangeLocation = async (code: string) => {
    setIsLoading(true)
    try {
      const storingEntry = await StoringEntryAPI.updateProductLocation(
        currentStoringEntry!._id,
        code
      )
      notify.success('Cập nhật vị trí thành công')
      updateStoringLocation(storingEntry)

      onDismissModal()
    } catch (error) {
      return notify.errorFromServer(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onShowModal = () => {
    setIsShowModal(true)
  }

  const onClickEdit = (storingEntry: IStoringEntry) => {
    setCurrentStoringEntry(storingEntry)
    onShowModal()
  }

  return (
    <>
      <IonList>
        {map(storingEntries, (storingEntry) => (
          <StoringEntryItem
            key={storingEntry._id}
            storingEntry={storingEntry}
            onClickEdit={onClickEdit}
          />
        ))}
      </IonList>
      {currentStoringEntry && (
        <ModalSearchProductLocations
          show={isShowModal}
          onDismiss={onDismissModal}
          product={{
            _id: currentStoringEntry.product,
            description: currentStoringEntry.description,
            sku: currentStoringEntry.sku,
          }}
          onSelect={onChangeLocation}
        />
      )}
    </>
  )
}

export default StoringEntryList
