import apiClient from '../libs/api-service'

const UserAPI = {
  getAll: async () => {
    const user = await apiClient.get(`/api/users/listAllUserForWareHouseApp`).catch((err) => {
      throw err
    })
    return user
  },
}

export default UserAPI
