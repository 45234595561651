import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  useIonAlert,
} from '@ionic/react'
import { checkboxSharp, squareOutline } from 'ionicons/icons'
import { get, map, reduce, sumBy } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useMemo } from 'react'
import Header from '../../../components/Header'
import IItem from '../../Item/model/item'

type RestockReportProps = {
  restockReport: any
  resolveRestockReport: any
  waiting: boolean
}
const RestockReportInfoView = observer(
  ({ restockReport, resolveRestockReport, waiting }: RestockReportProps) => {
    const items = toJS(restockReport).items
    const [present] = useIonAlert()
    const takenEnoughQty = useMemo(
      () =>
        reduce(
          items,
          (count: number, item: IItem): number =>
            item.takenQuantity === item.restockQuantity ? (count += 1) : count,
          0
        ),
      [items]
    )

    const notTakenQty = useMemo(
      () =>
        reduce(
          items,
          (count: number, item: IItem): number =>
            get(item, 'takenQuantity', 0) === 0 ? (count += 1) : count,
          0
        ),
      [items]
    )

    const totalQty = items.length

    const takenMisingQty = useMemo(
      () => totalQty - notTakenQty - takenEnoughQty,
      [notTakenQty, takenEnoughQty]
    )

    const totalRestockQuantity = useMemo(
      () => sumBy(items, 'restockQuantity'),
      [items]
    )

    const totalTakenQuantity = useMemo(() => sumBy(items, 'takenQuantity'), [
      items,
    ])

    const calculateMissing = useMemo(() => {
      const missingPercent =
        (1 - (totalTakenQuantity || 0) / totalRestockQuantity) * 100
      return missingPercent.toFixed(2)
    }, [totalTakenQuantity])

    const startTime = useMemo(() => {
      const timesTaken = map(items, (item) => moment(item.time))
      const minTimeTaken = moment.min(timesTaken)
      return minTimeTaken
    }, [items])

    const restockReportInfo = {
      takenEnoughQty,
      totalQty,
      notTakenQty,
      takenMisingQty,
      totalRestockQuantity,
      totalTakenQuantity,
      startTime,
      calculateMissing,
    }
    const infoContents = [
      `Restock: ${restockReport.restockReportId}`,
      `Vị trí: ${restockReport.warehouseRestock.code} => ${restockReport.outlet.code}`,
      `Ngày tạo: ${moment(restockReport.created).format('DD/MM/YYYY')}`,
      `Ngày lấy: ${moment(restockReport.startTime).format('DD/MM/YYYY')}`,
      `Người lấy: ${get(
        restockReport,
        'assignedStaff.displayName',
        'Chưa có'
      )}`,
    ]
    const skuContents = [
      `Tổng số sku: ${restockReportInfo.totalQty}`,
      `Số sku lấy đủ: ${restockReportInfo.takenEnoughQty}`,
      `Số sku lấy thiếu: ${restockReportInfo.takenMisingQty}`,
      `Số sku chưa lấy: ${restockReportInfo.notTakenQty}`,
    ]

    const missingRestockContents = [
      `Số sản phẩm cần lấy: ${restockReportInfo.totalRestockQuantity}`,
      `Số sản phẩm đã lấy: ${restockReportInfo.totalTakenQuantity || 0}`,
      `Missing restock: ${restockReportInfo.calculateMissing}%`,
    ]
    return (
      <>
        <IonPage>
          <Header title={`Restock ${get(restockReport, 'restockReportId')}`} />
          <IonContent>
            <IonList mode='ios'>
              <IonRow
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <IonButton
                  onClick={() => {
                    present({
                      cssClass: 'my-css',
                      header: 'Confirm Done Restock',
                      message: `Bạn thực sự muốn hoàn thành restock ${restockReport?.restockReportId}`,
                      buttons: [
                        'Cancel',
                        {
                          text: 'Ok',
                          handler: (d) => resolveRestockReport(restockReport),
                        },
                      ],
                    })
                  }}
                  style={{ width: '80%', height: '50px', fontSize: '18px' }}
                  color={!!restockReport?.resolved ? 'success' : 'dark'}
                >
                  {!!restockReport?.resolved ? (
                    <>
                      <IonIcon icon={checkboxSharp}></IonIcon>
                    </>
                  ) : (
                    <>
                      <IonIcon icon={squareOutline}></IonIcon>
                    </>
                  )}
                  <IonLabel>Hoàn thành</IonLabel>
                </IonButton>
              </IonRow>

              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle
                    style={{
                      fontSize: '18px',
                      paddingBottom: '2px',
                      borderBottom: '1px solid black',
                    }}
                  >
                    Overview
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  {skuContents.map((content, key) => {
                    return (
                      <React.Fragment key={key}>
                        <IonItem lines='none'>
                          <IonLabel
                            style={{ fontWeight: 500, overflow: 'visible' }}
                          >
                            {content}
                          </IonLabel>
                        </IonItem>
                      </React.Fragment>
                    )
                  })}
                </IonCardContent>
                <IonCardHeader>
                  <IonCardSubtitle
                    style={{
                      borderBottom: '1px solid black',
                    }}
                  ></IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  {missingRestockContents.map((content, key) => {
                    return (
                      <React.Fragment key={key}>
                        <IonItem
                          lines='none'
                          style={{ fontWeight: 500, overflow: 'auto' }}
                        >
                          <IonLabel
                            style={{ fontWeight: 500, overflow: 'visible' }}
                          >
                            {content}
                          </IonLabel>
                        </IonItem>
                      </React.Fragment>
                    )
                  })}
                </IonCardContent>
                <IonCardHeader>
                  <IonCardSubtitle
                    style={{
                      borderBottom: '1px solid black',
                    }}
                  ></IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  {infoContents.map((content, key) => {
                    return (
                      <React.Fragment key={key}>
                        <IonItem
                          lines='none'
                          style={{ fontWeight: 500, overflow: 'auto' }}
                        >
                          <IonLabel
                            style={{ fontWeight: 500, overflow: 'visible' }}
                          >
                            {content}
                          </IonLabel>
                        </IonItem>
                      </React.Fragment>
                    )
                  })}
                </IonCardContent>
              </IonCard>
            </IonList>
          </IonContent>
        </IonPage>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={waiting}
          message={'Đang tải...'}
        />
      </>
    )
  }
)

export default RestockReportInfoView
