import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import IRestockReport from '../model/restock-report'
import RestockReportInfoView from './RestockReportInfoView'

const RestockReportInfo = observer(() => {
  const { restockReportStore } = useContext(AppContext)
  const { restockReport, resolve } = restockReportStore
  const [waiting, setWaiting] = useState(false)
  const notify = useNotify()
  async function resolveRestockReport(
    restockReport: IRestockReport | undefined
  ) {
    if (restockReport) {
      setWaiting(true)
      resolve(restockReport._id)
        .catch((err) => {
          return notify.errorFromServer(err)
        })
        .finally(() => {
          setWaiting(false)
        })
    }
  }

  return (
    <RestockReportInfoView
      resolveRestockReport={resolveRestockReport}
      restockReport={restockReport}
      waiting={waiting}
    />
  )
})

export default RestockReportInfo
