import {
  createGesture,
  Gesture,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonImg,
  IonLoading,
  IonPage,
  IonRow,
  useIonModal,
} from '@ionic/react'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { CountQuantityModal } from '../../../components/CountQuantityModal'
import Header from '../../../components/Header'
import IItem, { Action } from '../model/item'

type ItemProps = {
  item: IItem
  handleCountMobile: any
  waiting: boolean
  handleChangeItem: any
}

const ItemDetailView = observer(
  ({ item, handleCountMobile, waiting, handleChangeItem }: ItemProps) => {
    const windowWidth = window.innerWidth

    const itemDetailView = document.querySelector(
      '#item-detail-view'
    ) as HTMLElement
    const itemDetailViewNode = itemDetailView?.parentNode?.querySelector(
      '#item-detail-view'
    ) as Node

    useEffect(() => {
      if (!itemDetailViewNode) return

      const itemDetailViewStyle = itemDetailView.style

      const swipe: Gesture = createGesture({
        el: itemDetailViewNode,
        gestureName: 'swipe',

        onStart: () => {
          itemDetailViewStyle.transition = ''
        },
        onMove: (ev) => {
          itemDetailViewStyle.transform = `translateX(${ev.deltaX}px)`
        },
        onEnd: (ev) => {
          itemDetailViewStyle.transition = 'all 0.5s ease-out'
          if (ev.deltaX > windowWidth / 3) {
            handleChangeItem(item, Action.Back)
          } else if (ev.deltaX < -windowWidth / 3) {
            handleChangeItem(item, Action.Next)
          }
          itemDetailViewStyle.transition = ''
          itemDetailViewStyle.transform = ''
        },
      })

      swipe.enable()

      return () => {
        swipe.destroy()
      }
    }, [itemDetailViewNode, item])

    const handleDismiss = () => {
      dismiss()
    }

    const [present, dismiss] = useIonModal(CountQuantityModal, {
      item,
      onDismiss: handleDismiss,
      handleCountMobile: handleCountMobile,
    })
    return (
      <>
        {item && (
          <>
            <IonPage>
              <Header title={`${get(item, 'sku')} `} />
              <IonContent
                fullscreen
                className='ion-padding ion-text-center '
                style={{ display: 'flex' }}
                id='item-detail-view'
              >
                <IonCard
                  className='card'
                  color={
                    item.takenQuantity === item.restockQuantity
                      ? 'success'
                      : item.takenQuantity
                      ? 'warning'
                      : 'light'
                  }
                >
                  <IonCardHeader className='ion-text-center'>
                    <IonCardTitle>
                      <IonRow>
                        <IonCol
                          className='ion-text-left'
                          style={{ lineHeight: 1.5 }}
                        >
                          {item.sku} - {item.description}
                        </IonCol>
                        <IonCol
                          className='ion-text-right'
                          style={{ lineHeight: '1.5' }}
                        >
                          {'Tồn'} {item.khoQuantity}
                          <br />
                          {'Cần'} {item.restockQuantity}
                          <br />
                          {item?.takenQuantity >= 0 &&
                            `Đã lấy ${item.takenQuantity}`}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          className='ion-text-left'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {get(item, 'currentLocation.code', '')}
                        </IonCol>
                        <IonCol className='ion-text-right'>
                          <IonButton
                            onClick={() => {
                              present({
                                cssClass: 'my-custom-modal-css',
                              })
                            }}
                            type='submit'
                            expand='block'
                          >
                            Nhập
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonImg src={get(item, 'product.imageLink', '')} />
                </IonCard>
              </IonContent>
            </IonPage>
            <IonLoading
              cssClass='my-custom-class'
              isOpen={waiting}
              message={'Đang tải...'}
            />
          </>
        )}
      </>
    )
  }
)

export default ItemDetailView
