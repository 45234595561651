import {
  IonCol,
  IonDatetime,
  IonInput,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react'
import { get } from 'lodash'
import React from 'react'
import { TOrderItem } from '../model/buyingEntries'
function CheckConfirmBuyingEntry({ item }: { item: TOrderItem }) {
  function isFoodProduct(item: TOrderItem) {
    if (get(item, 'product.category.group.name') === 'FOOD') {
      return true
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#E5E5E5',
        borderRadius: '10px',
        padding: '10px 3px',
        margin: '10px 0',
      }}
    >
      <IonRow>
        <IonCol size='12' className='ion-text-center'>
          <IonText style={{ fontWeight: 500 }}>
            <b>
              {item.sku} - {item.description}
            </b>
          </IonText>
        </IonCol>
        <IonCol size='3'>
          <IonText style={{ fontWeight: 500, fontSize: '15px' }}>
            Đặt: <b>{item.buyingQuantity}</b>
          </IonText>
        </IonCol>
        <IonCol size='4'>
          <IonText style={{ fontWeight: 500, fontSize: '15px' }}>
            Đã nhận: <b>{item.arrivedQuantity}</b>
          </IonText>
        </IonCol>
        <IonCol size='5'>
          <IonText style={{ fontWeight: 500, fontSize: '15px' }}>
            Chưa nhận: <b>{item.arrivingQuantity}</b>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow style={{ display: 'flex', alignItems: 'center' }}>
        <IonCol size='12' className='ion-text-left'>
          <IonLabel position='floating'>
            <strong>Số lượng thực nhận:</strong>
          </IonLabel>
          <IonInput
            style={{
              border: '1px solid black',
              borderRadius: '5px',
              height: '10%',
              backgroundColor: '#FBFBFB',
            }}
            type='number'
            onIonChange={(e) => {
              if (e.detail.value) {
                return (item.quantity = +e.detail.value)
              }
            }}
          />
        </IonCol>

        {isFoodProduct(item) && (
          <IonCol size='6'>
            <IonLabel position='floating'>
              <strong>NSX:</strong>
            </IonLabel>
            <IonDatetime
              pickerFormat={'DD/MM/YYYY'}
              style={{
                border: '1px solid black',
                borderRadius: '5px',
                height: '10%',
                backgroundColor: '#FBFBFB',
              }}
              displayFormat='MM/DD/YYYY'
              onIonChange={(e) => {
                item.manufacturingDate = new Date(e.detail.value!)
              }}
            />
          </IonCol>
        )}

        {isFoodProduct(item) && (
          <IonCol size='6'>
            <IonLabel position='floating'>
              <strong>HSD:</strong>
            </IonLabel>
            <IonDatetime
              pickerFormat={'DD/MM/YYYY'}
              style={{
                border: '1px solid black',
                borderRadius: '5px',
                height: '10%',
                backgroundColor: '#FBFBFB',
              }}
              displayFormat='MM/DD/YYYY'
              onIonChange={(e) => {
                item.expirationDate = new Date(e.detail.value!)
              }}
            />
          </IonCol>
        )}
      </IonRow>
    </div>
  )
}

export default CheckConfirmBuyingEntry
