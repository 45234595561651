import { action, makeObservable, observable } from 'mobx'
import { RootStore } from '../../../models/root-store'

export class PlTaskStore {
  rootStore: RootStore

  isReload: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.isReload = true

    makeObservable(this, {
      isReload: observable,
      setReload: action,
    })
  }

  setReload = (status: boolean) => {
    this.isReload = status
  }
}
