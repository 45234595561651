import { yupResolver } from '@hookform/resolvers/yup'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonTextarea,
  IonText,
  IonInput,
  IonLoading,
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Header from '../components/Header'
import useNotify from '../hook/useNotify'
import FeedbackAPI from '../api/feedbackAPI'
import { starOutline, star } from 'ionicons/icons'
import { range } from 'lodash'
import { useState } from 'react'
import UploadImages from '../components/UploadImages'

let schema = yup.object().shape({
  rate: yup.number().min(1).required('Bạn chưa đánh giá sao'),
  content: yup.string().required('Bạn chưa nhập nội dung'),
})

type FormValues = {
  rate: number
  content: string
}
const FeedbackView = observer(() => {
  const notify = useNotify()
  const [waiting, setWaiting] = useState<boolean>()
  const handleFeedback: SubmitHandler<FormValues> = async ({
    rate,
    content,
  }) => {
    setWaiting(true)
    await FeedbackAPI.postFeedback(rate, content)
      .then(() => {
        notify.success('Send Feedback Successfully')
      })
      .catch((err) => {
        notify.errorFromServer(err)
      })
      .finally(() => {
        setWaiting(false)
      })
  }
  const method = useForm<FormValues>({
    defaultValues: {
      rate: 0,
      content: '',
    },
    resolver: yupResolver(schema),
  })
  return (
    <IonPage>
      <FeedBackForm method={method} handleFeedback={handleFeedback} />
      <IonLoading
        cssClass='my-custom-class'
        isOpen={!!waiting}
        message={'Đang tải...'}
      />
    </IonPage>
  )
})

type FormProps = {
  method: any
  handleFeedback: any
}

export interface IImageUpload {
  link: string
}

const FeedBackForm: React.FC<FormProps> = ({ method, handleFeedback }) => {
  const { handleSubmit, errors, register, setValue } = method
  const [rate, setRate] = useState(0)
  const handleRate = (star: number) => {
    if (rate !== star) {
      setRate(star)
      setValue('rate', star)
    }
  }

  const [imagesUpload, setImagesUpload] = useState<IImageUpload[]>([])

  return (
    <>
      <Header title='Feedback' />
      <IonContent fullscreen className='ion-padding'>
        <form onSubmit={handleSubmit(handleFeedback)}>
          <IonGrid>
            <IonRow
              style={{
                justifyContent: 'center',
                paddingBottom: '30px',
                fontSize: '50px',
              }}
            >
              {range(5).map((index) => {
                index++
                return (
                  <IonIcon
                    color={index <= rate ? 'warning' : 'default'}
                    key={index}
                    icon={index <= rate ? star : starOutline}
                    onClick={() => handleRate(index)}
                  ></IonIcon>
                )
              })}
            </IonRow>
            <IonInput
              style={{ display: 'none' }}
              type='text'
              name='rate'
              ref={register}
            ></IonInput>
            {errors && errors['rate'] && (
              <IonRow style={{ justifyContent: 'center' }}>
                <IonText color='danger' className='ion-padding-start'>
                  <small>{errors['rate'].message}</small>
                </IonText>
              </IonRow>
            )}
            <IonRow>
              <IonCol sizeMd='12'>
                <IonLabel
                  style={{
                    fontSize: '30px',
                    paddingBottom: '20px',
                  }}
                >
                  Nội dung
                </IonLabel>
                <IonTextarea
                  style={{ height: 'auto' }}
                  className='border-black'
                  placeholder='From team tech with love ♥'
                  name='content'
                  ref={register}
                  rows={15}
                ></IonTextarea>
                {errors && errors['content'] && (
                  <IonText color='danger' className='ion-padding-start'>
                    <small>{errors['content'].message}</small>
                  </IonText>
                )}
              </IonCol>
            </IonRow>
            <IonRow style={{ justifyContent: 'center' }}>
              <IonButton
                style={{ width: '200px' }}
                expand='block'
                type='submit'
              >
                Gửi
              </IonButton>
            </IonRow>
            {/* <IonRow>
              <UploadImages
                setImagesUpload={setImagesUpload}
                imagesUpload={imagesUpload}
              />
            </IonRow> */}
          </IonGrid>
        </form>
      </IonContent>
    </>
  )
}

export default FeedbackView
