// import { BarcodeScanResult } from '@ionic-native/barcode-scanner'
import { IonImg, IonPage, IonContent, IonText } from '@ionic/react'
// import useNotify from '../hook/useNotify'
// import BarScanner from './BarScanner'
import Header from './Header'
const image = '/assets/images/Supermarket workers-pana.svg'
const Home = () => {
  // const notify = useNotify()
  return (
    <IonPage>
      <Header title='' />
      <IonContent fullscreen>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <IonImg style={{ width: '90%' }} src={image}></IonImg>
          <IonText
            style={{ fontSize: '30px', fontWeight: 'bold' }}
            color='primary'
          >
            Warehouse 4handy
          </IonText>
          {/* <BarScanner
            text={'Quét mã'}
            callback={(data: BarcodeScanResult) => {
              if (!data.text) {
                return notify.error('Không quét được dữ liệu')
              }
              return notify.info(data.text)
            }}
          /> */}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Home
