import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonToggle,
  IonToolbar,
  useIonModal,
  useIonPopover,
} from '@ionic/react'
import { optionsOutline } from 'ionicons/icons'
import { filter, forEach, get, keys, map, pickBy, sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { CountQuantityModal } from '../../../components/CountQuantityModal'
import Header from '../../../components/Header'
import IItem from '../../Item/model/item'

type RestockReportProps = {
  restockReport: any
  viewProductDetail: any
  waiting: boolean
  quickCountMobile: any
}
const RestockReportDetailView = observer(
  ({
    restockReport,
    viewProductDetail,
    waiting,
    quickCountMobile,
  }: RestockReportProps) => {
    const [statuses, setStatuses] = useState({
      taken_missing: true,
      not_taken: true,
      all: false,
      taken_enough: false,
    })

    const filterRestockReportItems = filter(
      sortBy(
        restockReport?.items,
        (item: IItem) => {
          return !(item.takenQuantity === item.restockQuantity)
        },
        (item: IItem) => {
          return item.takenQuantity
        }
      ),
      (item: IItem) => {
        let condition = false
        if (get(statuses, 'all', false)) {
          condition = condition || true
        }
        if (get(statuses, 'taken_enough', false)) {
          condition = condition || item.takenQuantity === item.restockQuantity
        }
        if (get(statuses, 'taken_missing', false)) {
          condition =
            condition ||
            (!!item.takenQuantity &&
              item.takenQuantity !== item.restockQuantity)
        }
        if (get(statuses, 'not_taken', false)) {
          condition = condition || !item.takenQuantity
        }
        return condition
      }
    )
    const filterItems = filterRestockReportItems

    function selectOption(e: any) {
      if (e.detail.value === 'all') {
        if (e.detail.checked === true) {
          setStatuses({
            taken_missing: true,
            not_taken: true,
            all: true,
            taken_enough: true,
          })
          setAllDisabled(true)
        } else {
          setStatuses({
            taken_missing: false,
            not_taken: false,
            all: false,
            taken_enough: false,
          })
          setAllDisabled(false)
        }
      } else {
        setStatuses({ ...statuses, [e.detail.value]: e.detail.checked })
      }
    }

    const [allDisabled, setAllDisabled] = useState(false)
    const PopoverList: React.FC<{
      onHide: () => void
    }> = ({ onHide }) => {
      return (
        <IonList>
          <IonItem>
            <IonLabel>Tất cả</IonLabel>
            <IonToggle
              checked={statuses.all}
              value='all'
              onIonChange={(e) => {
                selectOption(e)
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Lấy đủ</IonLabel>
            <IonToggle
              color='success'
              checked={statuses.taken_enough}
              disabled={allDisabled}
              value='taken_enough'
              onIonChange={(e) => {
                selectOption(e)
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Lấy thiếu</IonLabel>
            <IonToggle
              color='warning'
              checked={statuses.taken_missing}
              disabled={allDisabled}
              value='taken_missing'
              onIonChange={(e) => {
                selectOption(e)
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Chưa lấy</IonLabel>
            <IonToggle
              color='danger'
              checked={statuses.not_taken}
              disabled={allDisabled}
              value='not_taken'
              onIonChange={(e) => {
                selectOption(e)
              }}
            />
          </IonItem>
          <IonItem lines='none' detail={false} button onClick={onHide}>
            Close
          </IonItem>
        </IonList>
      )
    }
    const [present, dismiss] = useIonPopover(PopoverList, {
      onHide: () => dismiss(),
    })

    const statusesInText = (statuses: Array<String>) => {
      const translatedStatus: string[] = []
      if (statuses.includes('all')) {
        return 'Tất cả'
      }
      forEach(statuses, (status) => {
        switch (status) {
          case 'all':
            translatedStatus.push('Tất cả')
            break
          case 'taken_enough':
            translatedStatus.push('Lấy đủ')
            break
          case 'taken_missing':
            translatedStatus.push('Lấy thiếu')
            break
          case 'not_taken':
            translatedStatus.push('Chưa lấy')
            break
        }
      })
      return translatedStatus.join(', ')
    }

    const handleDismiss = () => {
      dismissModal()
    }
    const [currentItem, setCurrentItem] = useState()
    const [presentModal, dismissModal] = useIonModal(CountQuantityModal, {
      item: currentItem,
      onDismiss: handleDismiss,
      handleCountMobile: quickCountMobile,
    })
    return (
      <>
        <IonPage>
          <Header title={`Restock ${get(restockReport, 'restockReportId')}`}>
            <IonToolbar>
              <IonItem
                onClick={(e) =>
                  present({
                    event: e.nativeEvent,
                  })
                }
              >
                <IonIcon icon={optionsOutline}></IonIcon>
                <IonLabel>{statusesInText(keys(pickBy(statuses)))}</IonLabel>
              </IonItem>
            </IonToolbar>
          </Header>
          <IonContent fullscreen className='ion-padding ion-text-center'>
            <IonGrid>
              {map(filterItems, (item) => {
                return (
                  <IonCard
                    className='card'
                    color={
                      item.takenQuantity === item.restockQuantity
                        ? 'success'
                        : item.takenQuantity
                        ? 'warning'
                        : 'light'
                    }
                    key={item.sku}
                    onClick={() => viewProductDetail(get(item, 'product._id'))}
                  >
                    <IonCardHeader className='ion-text-center'>
                      <IonCardTitle>
                        <IonRow>
                          <IonCol className='ion-text-left'>
                            {item.sku} - {item.description}
                          </IonCol>
                          <IonCol
                            className='ion-text-right'
                            style={{ lineHeight: '1.5' }}
                          >
                            {'Tồn'} {item.khoQuantity}
                            <br />
                            {'Cần'} {item.restockQuantity}
                            <br />
                            {item?.takenQuantity >= 0 &&
                              `Đã lấy ${item.takenQuantity}`}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol
                            className='ion-text-left'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {get(item, 'currentLocation.code', '')}
                          </IonCol>
                          <IonCol className='ion-text-right'>
                            <IonButton
                              onClick={(e) => {
                                e.stopPropagation()
                                setCurrentItem(item)
                                presentModal({
                                  cssClass: 'my-custom-modal-css',
                                })
                              }}
                            >
                              Nhập
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                )
              })}
            </IonGrid>
          </IonContent>
        </IonPage>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={waiting}
          message={'Đang tải...'}
        />
      </>
    )
  }
)

export default RestockReportDetailView
