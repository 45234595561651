import { IonContent, IonLoading, IonPage } from '@ionic/react'
import { filter, forEach, reduce, split, take } from 'lodash'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchBar from '../../../components/SearchBar'
import BuyingEntryList from '../components/BuyingEntryList'
import { IBuyingEntry } from '../model/buyingEntries'

type TProps = {
  buyingEntries: IBuyingEntry[]
  isLoading: boolean
}

const ConfirmBuyingEntryView = ({ buyingEntries, isLoading }: TProps) => {
  const [buyingEntriesFilterd, setBuyingEntriesFilterd] = useState<
    IBuyingEntry[]
  >([])
  useEffect(() => {
    setBuyingEntriesFilterd(buyingEntries)
  }, [buyingEntries])

  const onSubmitSearch = (e: any, textSearch: string) => {
    e.preventDefault()
    if (!textSearch.trim().length) {
      setBuyingEntriesFilterd(buyingEntries)
      return
    }

    const keywords = split(textSearch, ' ')

    let cond = reduce(
      keywords,
      (result, keywork) => {
        return result + `(?=${keywork}).*`
      },
      ''
    )
    const regexSearch = new RegExp(cond, 'i')

    const filtered: IBuyingEntry[] = filter(buyingEntries, (buyingEntry) => {
      let fullDescription =
        ' ' + buyingEntry.buyingEntryId + ' ' + buyingEntry.vendor.name
      forEach(buyingEntry.orderItems, (item) => {
        fullDescription += ' ' + item.sku + ' ' + item.description
      })

      return regexSearch.test(fullDescription)
    })

    setBuyingEntriesFilterd(take(filtered, 10))
  }

  return (
    <IonPage>
      <Header title='Confirm Buying Entries' />
      <IonContent>
        <SearchBar
          onSubmit={onSubmitSearch}
          placeHolder='Nhập mã sản phẩm hoặc BE'
        />

        <BuyingEntryList buyingEntries={buyingEntriesFilterd} />
      </IonContent>
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
}

export default ConfirmBuyingEntryView
