import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import PlTaskAPI from '../api/plTaskAPI'
import { IPlTask } from '../model/plTask'
import ConfirmPlTaskView from './ConfirmPlTaskView'

const ConfirmPlTask = observer(() => {
  const { currentShiftStore, plTaskStore } = useContext(AppContext)
  const { isReload, setReload } = plTaskStore
  const notify = useNotify()
  const history = useHistory()

  const [plTasks, setPlTasks] = useState<IPlTask[] | []>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const { currentShift } = currentShiftStore

  async function fetchPlTasks({
    shift,
    outlet,
  }: {
    shift: string
    outlet: string
  }) {
    setLoading(true)
    const _plTasks = await PlTaskAPI.getAll({ shift, outlet })
      .catch((err) => {
        return notify.errorFromServer(err)
      })
      .finally(() => {
        setLoading(false)
      })
    setReload(false)
    setPlTasks(_plTasks)
  }

  useEffect(() => {
    if (!currentShift) {
      notify.info('Bạn cần đăng nhập ca trực để load dữ liệu')
      setPlTasks([])
    }
  }, [currentShift])

  useEffect(() => {
    let isFetch = true

    if (currentShift && isFetch && isReload) {
      const { shift, outlet } = currentShift
      fetchPlTasks({
        shift: shift,
        outlet: outlet._id,
      })
    }
    return () => {
      isFetch = false
    }
  }, [isReload])

  return <ConfirmPlTaskView plTasks={plTasks} isLoading={isLoading} />
})

export default ConfirmPlTask
