import { IBuyingEntry } from '../model/buyingEntries'
import apiClient from '../../../libs/api-service'

const BuyingEntriesAPI = {
  getAll: async ({ shift, outlet }: { shift?: String; outlet?: String }) => {
    const buyingEntries = await apiClient.get('/api/buying-entries', {
      params: {
        shift,
        destinations: [outlet],
        statuses: ['received', 'ordered'],
      },
    })
    return buyingEntries
  },

  findOne: async (buyingEntryId: string) => {
    return apiClient.get(`/api/buying-entries/${buyingEntryId}`)
  },

  confirm: async (buyingEntry: IBuyingEntry) => {
    return apiClient.put(
      `/api/buying-entries/${buyingEntry._id}/receive-order-items`,
      {
        ...buyingEntry,
      }
    )
  },
  noticeNonBuyingEntry: async (nonBuyingEntry: Object) => {
    return apiClient.put(`/api/buying-entries/notice-product-no-be`, {
      ...nonBuyingEntry,
    })
  },
}

export default BuyingEntriesAPI
