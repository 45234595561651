import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Redirect } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import BuyingEntryDetail from '../features/BuyingEntries/components/BuyingEntryDetail'
import ConfirmBuyingEntries from '../features/BuyingEntries/page/ConfirmBuyingEntries'

const BuyingEntries = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Redirect
          exact
          from='/buying-entries'
          to='/buying-entries/product-locations'
        />
        <PrivateRoute
          path='/buying-entries/confirm-buying-entries'
          exact
          component={ConfirmBuyingEntries}
        />
        <PrivateRoute
          path='/buying-entries/confirm-buying-entries/:buyingEntryId'
          exact
          component={BuyingEntryDetail}
        />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default BuyingEntries
