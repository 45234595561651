import { action, makeObservable, observable, runInAction } from 'mobx'
import RestockReportAPI from '../../../api/restockReportAPI'
import LocalStorage from '../../../libs/local-storages'
import { RootStore } from '../../../models/root-store'
import RestockReport from '../model/restock-report'

export class RestockReportStore {
  rootStore: RootStore
  restockReport: RestockReport | undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    const restockReport = LocalStorage.getRestockReport()
    if (restockReport) {
      this.setRestockReport(restockReport)
    }

    makeObservable(this, {
      restockReport: observable,
      fetchById: action,
      countMobile: action,
      setRestockReport: action,
      resolve: action,
    })
  }

  setRestockReport = (restockReport: RestockReport) => {
    this.restockReport = restockReport
    LocalStorage.setRestockReport(restockReport)
  }

  fetchById = async (restockReportId: string) => {
    try {
      const restockReport = await RestockReportAPI.fetchById(restockReportId)
      runInAction(() => {
        this.setRestockReport(restockReport)
      })
      return restockReport
    } catch (error) {
      throw error
    }
  }
  resolve = async (restockReportId: string) => {

    try {
      const restockReport = await RestockReportAPI.resolve(restockReportId)
      runInAction(() => {
        this.setRestockReport(restockReport)
      })
      return restockReport
    } catch (error) {
      throw error
    }
  }
  countMobile = async ({
    restockReportId,
    productId,
    quantity,
  }: {
    restockReportId: string
    productId: string
    quantity: number
  }) => {
    try {
      const { item } = await RestockReportAPI.countMobile(
        restockReportId,
        productId,
        quantity
      )
      return { item }
    } catch (error) {
      throw error
    }
  }

}
