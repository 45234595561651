import { IonButton, IonHeader, IonInput, IonPage, IonRow } from '@ionic/react'
import React, { FormEvent, useRef } from 'react'
import IItem from '../features/Item/model/item'
import useNotify from '../hook/useNotify'

export const CountQuantityModal = ({
  onDismiss,
  item,
  handleCountMobile,
}: {
  onDismiss: Function
  item: IItem
  handleCountMobile: Function
}) => {
  const inputRef = useRef<any>()
  const notify = useNotify()

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    const quantity = inputRef.current.value
    if (quantity < 0 || (item && quantity > item.restockQuantity)) {
      return notify.error(
        `Sô lượng phải >= 0 và <= ${item ? item.restockQuantity : 0}`
      )
    }
    await handleCountMobile(item, quantity)
    onDismiss()
  }

  return (
    <IonPage>
      <IonHeader className='header'>Nhập số lượng</IonHeader>
      <IonRow className='content'>
        <IonInput
          className='quantity-input'
          type='tel'
          name='quantity'
          ref={inputRef}
          value={item?.restockQuantity}
          onFocus={(e: any) => {
            e.target.select()
          }}
        ></IonInput>
      </IonRow>
      <IonRow className='footer'>
        <IonButton
          class='footer-btn'
          color={'danger'}
          onClick={() => onDismiss()}
        >
          Close
        </IonButton>
        <IonButton
          type='submit'
          color={'success'}
          onClick={submit}
          class='footer-btn'
        >
          OK
        </IonButton>
      </IonRow>
    </IonPage>
  )
}
