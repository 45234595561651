import { IonContent, IonLoading, IonPage } from '@ionic/react'

import { useState } from 'react'
import { filter, reduce, split, take } from 'lodash'
import Header from '../../../components/Header'
import ProductLocationList from '../components/ProductLocationList'
import { IProductLocation } from '../model/productLocation'
import SearchBar from '../../../components/SearchBar'

type TProps = {
  productLocations: IProductLocation[]
  isLoading: boolean
}

const ProductLocationView = ({ productLocations, isLoading }: TProps) => {
  const [productFiltered, setProductFiltered] = useState<IProductLocation[]>([])

  const onSubmitSearch = (e: any, textSearch: string) => {
    e.preventDefault()

    if (textSearch === '') {
      return
    }

    const keywords = split(textSearch, ' ')

    let cond = reduce(
      keywords,
      (result, keywork) => {
        return result + `(?=${keywork}).*`
      },
      ''
    )

    const regexSearch = new RegExp(cond, 'i')

    const filtered: IProductLocation[] = filter(productLocations, (product) => {
      const fullDescription = product.sku + ' ' + product.description
      return regexSearch.test(fullDescription)
    })

    setProductFiltered(take(filtered, 10))
  }

  return (
    <IonPage>
      <Header title='Product Locations' />
      <IonContent>
        <SearchBar onSubmit={onSubmitSearch} />
        <ProductLocationList productLocations={productFiltered} />
      </IonContent>
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
}

export default ProductLocationView
