import { useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { AppContext } from '../context'

const Logout: React.FC = () => {
  const { authenticationStore, currentShiftStore } = useContext(AppContext)
  const history = useHistory()

  const onLogout = async () => {
    await authenticationStore.logout()
    currentShiftStore.removeCurrentShift()
    history.replace('/login')
  }
  useEffect(() => {
    let subcribe = true
    if (subcribe) {
      onLogout()
    }
    return () => {
      subcribe = false
    }
  }, [])

  return null
}

export default Logout
