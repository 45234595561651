import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import ENV from '../env/env'
import history from './history'
import LocalStorage from './local-storages'

export const axiosClient = axios.create({ baseURL: ENV.WORK_URL })

axiosClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = LocalStorage.getToken()
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data
  },
  (error) => {
    if (error.response.status === 401) {
      LocalStorage.clearCredentials()
      history.replace('/login')
    }
    return Promise.reject(error.response)
  }
)

export default axiosClient
