import apiClient from '../libs/api-service'

const StoringEntryAPI = {
  getAll: async ({ shift, outlet }: { shift?: String; outlet?: String }) => {
    const storingEntries = await apiClient.get('/api/storing-entries', {
      params: { shift, outlet },
    })
    return storingEntries
  },

  updateProductLocation: async (
    storingEntryId: string,
    locationCode: string
  ) => {
    const storingEntry = await apiClient.put(
      `/api/storing-entries/${storingEntryId}/locations`,
      { locationCode }
    )

    return storingEntry
  },
}

export default StoringEntryAPI
