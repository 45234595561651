import { IonContent, IonLoading, IonPage } from '@ionic/react'
import { filter, reduce, split, take } from 'lodash'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchBar from '../../../components/SearchBar'
import PlTaskList from '../components/PlTaskList'
import { IPlTask } from '../model/plTask'

type TProps = {
  plTasks: IPlTask[]
  isLoading: boolean
}

const ConfirmPlTaskView = ({ plTasks, isLoading }: TProps) => {
  const [plTasksFilterd, setPlTasksFilterd] = useState<IPlTask[]>([])

  useEffect(() => {
    setPlTasksFilterd(plTasks)
  }, [plTasks])

  const onSubmitSearch = (e: any, textSearch: string) => {
    e.preventDefault()

    if (!textSearch.trim().length) {
      setPlTasksFilterd(plTasks)
      return
    }

    const keywords = split(textSearch, ' ')

    let cond = reduce(
      keywords,
      (result, keywork) => {
        return result + `(?=${keywork}).*`
      },
      ''
    )

    const regexSearch = new RegExp(cond, 'i')

    const filtered: IPlTask[] = filter(plTasks, (plTask) => {
      const fullDescription =
        plTask.sku + ' ' + plTask.description + ' ' + plTask.plTaskId
      return regexSearch.test(fullDescription)
    })

    setPlTasksFilterd(take(filtered, 10))
  }

  return (
    <IonPage>
      <Header title='Confirm Pl Tasks' />
      <IonContent>
        <SearchBar onSubmit={onSubmitSearch} />
        <PlTaskList plTasks={plTasksFilterd} />
      </IonContent>
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
}

export default ConfirmPlTaskView
