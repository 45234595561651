import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { filter, map } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useMemo, useState } from 'react'
import ProductLocationAPI from '../api/productLocationAPI'
import { AppContext } from '../context'
import useNotify from '../hook/useNotify'
import IProduct from '../models/product'

type TProps = {
  onDismiss: () => void
  onSelect: (code: string) => void
  show: boolean
  product: IProduct | undefined
}

const ModalSearchProductLocations = observer(
  ({ show, onDismiss, product, onSelect }: TProps) => {
    const [textSearch, setTextSearch] = useState<string>('')

    const { productLocationStore, currentShiftStore } = useContext(AppContext)
    const { currentShift } = currentShiftStore
    const notify = useNotify()
    const {
      locationCodes,
      setLocationCodes,
      setIsLoading,
    } = productLocationStore

    const onInputSearch = (e: any) => {
      const text = e.target.value
      setTextSearch(text)
    }

    const getLocationCodes = async () => {
      setIsLoading(true)
      try {
        let params = {}

        if (currentShift) {
          const { outlet } = currentShift
          params = { outlet: outlet._id || outlet }
        }

        const _locationCodes: string[] = await ProductLocationAPI.getLocationCodes(
          params
        )

        if (_locationCodes.length) {
          setLocationCodes(_locationCodes)
        }
      } catch (error) {
        return notify.errorFromServer(error)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      let subcribe = true
      if (subcribe && !locationCodes.length) {
        getLocationCodes()
      }
      return () => {
        subcribe = false
      }
    }, [])

    const filteredLocationCodes = useMemo(() => {
      if (show === false) {
        setTextSearch('')
      }

      if (textSearch === '') {
        return []
      }

      return filter(locationCodes, (code) =>
        code.includes(textSearch.toUpperCase())
      )
    }, [textSearch, show])

    return (
      <IonModal isOpen={show}>
        <IonHeader>
          <IonToolbar>
            <IonTitle className='ion-padding ion-text-center'>
              {product?.sku} - {product?.description}
            </IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonGrid>
              <IonRow className='ion-align-items-center'>
                <IonCol size='9'>
                  <IonInput
                    placeholder='Tìm vị trí'
                    value={textSearch}
                    onIonChange={onInputSearch}
                    debounce={500}
                    style={{ borderBottom: '1px solid #FF602E' }}
                  />
                </IonCol>
                <IonCol size='3'>
                  <IonButton>Clear</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {map(filteredLocationCodes, (code) => (
              <IonItem onClick={() => onSelect(code)} key={code}>
                {code}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        <IonButton color='medium' onClick={onDismiss}>
          Close Modal
        </IonButton>
      </IonModal>
    )
  }
)

export default ModalSearchProductLocations
