import { BarcodeScanResult } from '@ionic-native/barcode-scanner'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from '@ionic/react'
import { search } from 'ionicons/icons'
import { useRef, useState } from 'react'
import BarScanner from './BarScanner'

type TProps = {
  onSubmit: (e: any, textSeach: string) => void
  debounce?: number
  hasScanner?: boolean
  clean?: boolean
  scanCallback?: (data: BarcodeScanResult) => void
  placeHolder?: string
}

const SearchBar = ({
  onSubmit,
  debounce,
  hasScanner,
  scanCallback,
  placeHolder,
  clean,
}: TProps) => {
  const [textSearch, setTextSearch] = useState<string>('')
  const inputRef = useRef<any>()
  const onTextSearchChange = (e: any) => {
    const text = inputRef.current.value
    setTextSearch(text)

    if (debounce) {
      onSubmit(e, text)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        onSubmit(e, textSearch)
        if (clean) {
          setTextSearch('')
        }
      }}
      className='search'
    >
      <IonGrid>
        <IonRow className='ion-align-items-center'>
          <IonCol size={hasScanner ? '8' : '10'}>
            <IonInput
              ref={inputRef}
              value={textSearch}
              debounce={debounce ? debounce : 0}
              onIonChange={onTextSearchChange}
              placeholder={placeHolder || 'Tìm kiếm VD: B0001'}
              style={{
                borderBottom: '1px solid #ff602e',
              }}
            />
          </IonCol>
          <IonCol size='2'>
            <IonButton type='submit'>
              <IonIcon icon={search} />
            </IonButton>
          </IonCol>
          {hasScanner && (
            <IonCol size='2'>
              <BarScanner callback={scanCallback} />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default SearchBar
