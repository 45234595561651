import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
} from '@ionic/react'
import { closeCircleSharp } from 'ionicons/icons'
import { assignIn, find, get, map, some } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import UserAPI from '../../../api/userAPI'
import Header from '../../../components/Header'
import SearchBar from '../../../components/SearchBar'
import UploadImages from '../../../components/UploadImages'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import IUser from '../../../models/user'
import { IImageUpload } from '../../../pages/FeedbackView'
import BuyingEntriesAPI from '../api/buyingEntriesAPI'
import { IBuyingEntry, TOrderItem } from '../model/buyingEntries'
import CheckConfirmBuyingEntry from './CheckConfirmBuyingEntry'

const BuyingEntryDetail = observer(() => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [buyingEntry, setBuyingEntry] = useState<IBuyingEntry | null>(null)
  const [processStaffs, setProcessStaffs] = useState<IUser[]>([])

  const [noActualPayment, setNoActualPayment] = useState<boolean | undefined>(
    () => buyingEntry?.noActualPayment
  )
  const [noBillPayment, setNoBillPayment] = useState<boolean | undefined>(
    () => buyingEntry?.noBillPayment
  )

  const [actualPayment, setActualPayment] = useState<number>()
  const [billPayment, setBillPayment] = useState<number>()
  const [note, setNote] = useState<string>()
  const [paymentType, setPaymentType] = useState<string>()
  const [noBillReason, setNoBillReason] = useState<string>()

  const params = useParams()
  const buyingEntryId = get(params, 'buyingEntryId')
  const notify = useNotify()

  const [vendorProductImages, setVendorProductImages] = useState<
    IImageUpload[]
  >([])
  const [billImages, setBillImages] = useState<IImageUpload[]>([])
  const [orderItemReceiveds, setOrderItemReceiveds] = useState<TOrderItem[]>([])
  const history = useHistory()

  const fetchBuyingEntry = async (buyingEntryId: string) => {
    setLoading(true)
    const _buyingEntry = await BuyingEntriesAPI.findOne(buyingEntryId)
      .catch((err) => {
        return notify.errorFromServer(err)
      })
      .finally(() => {
        setLoading(false)
      })
    setBuyingEntry(_buyingEntry)
  }

  useEffect(() => {
    let isFetch = true
    if (buyingEntryId && isFetch) {
      fetchBuyingEntry(buyingEntryId)
    }
    return () => {
      isFetch = false
    }
  }, [buyingEntryId])

  const removeStaff = (index: number) => {
    setProcessStaffs((current: IUser[]) => {
      let clone = [...current]
      clone.splice(index, 1)
      return clone
    })
  }

  const { buyingEntryStore } = useContext(AppContext)
  const { setReload } = buyingEntryStore
  const confirm = async function () {
    if (!processStaffs.length) {
      return notify.error('Chưa có danh sách nhân viên xử lý')
    }
    if (processStaffs.length > 8) {
      return notify.error('Tối đa 8 nhân viên xử lý')
    }

    if (!actualPayment && !noActualPayment) {
      return notify.error('Chưa có tổng tiền thực trả')
    }
    if (!billPayment && !noBillPayment) {
      return notify.error('Chưa có tổng tiền trên hóa đơn')
    }
    if (!paymentType) {
      return notify.error('Chưa có phương thức thanh toán')
    }

    if (
      some(orderItemReceiveds, (item) => {
        return (
          get(item, 'product.category.group.name') === 'FOOD' &&
          (!item.manufacturingDate || !item.expirationDate)
        )
      })
    ) {
      return notify.error('Có sản phẩm chưa có NSX và HSD')
    }

    if (
      some(orderItemReceiveds, (item) => {
        return (
          item.expirationDate &&
          moment().endOf('day').isBefore(item.manufacturingDate)
        )
      })
    ) {
      return notify.error('NSX của các sản phẩm không được lớn hơn hôm nay')
    }

    if (
      some(orderItemReceiveds, (item) => {
        return (
          item.manufacturingDate &&
          moment().endOf('day').isAfter(item.expirationDate)
        )
      })
    ) {
      return notify.error('HSD của các sản phẩm phải lớn hơn ngày hôm nay')
    }

    if (
      some(orderItemReceiveds, (item) => {
        return !item.quantity || item.quantity === 0
      })
    ) {
      return notify.error('Có sản phẩm chưa nhập số lượng nhập kho')
    }

    if (!noBillReason && noBillPayment) {
      return notify.error('Chưa có lý do không có hóa đơn')
    }
    const _buyingEntry = assignIn(buyingEntry, {
      billImages,
      vendorProductImages,
      note,
      actualPayment,
      billPayment,
      paymentType,
      noActualPayment,
      noBillPayment,
      orderItemReceiveds,
      noBillReason,
      processStaffs,
    })
    setLoading(true)
    await BuyingEntriesAPI.confirm(_buyingEntry)
      .catch((err) => {
        return notify.errorFromServer(err)
      })
      .catch(() => {
        setLoading(false)
      })
    setReload(true)
    notify.success('Confirm thành công')
    history.goBack()
  }

  const [users, setUsers] = useState<IUser[]>()
  const fetchUser = async () => {
    const _users = await UserAPI.getAll()
    setUsers(_users)
  }

  useEffect(() => {
    let subcribe = true
    if (subcribe) {
      fetchUser()
    }
    return () => {
      subcribe = false
    }
  }, [])

  function addStaff(code: string) {
    if (!code) {
      return notify.error('Vui lòng nhập code người dùng')
    }

    const user = find(users, { code })
    if (!user) {
      return notify.error('Không tìm thấy người dùng')
    }

    if (find(processStaffs, { code: user.code })) {
      return notify.error('Người dùng này đã thêm rồi')
    }

    setProcessStaffs((current: IUser[]) => {
      let clone = [...current]
      clone = clone.concat(user)
      return clone
    })

    notify.success('Thêm người dùng thành công')
  }

  function handleStaff(e: any, textString: string) {
    e.preventDefault()
    addStaff(textString)
  }

  function handleSearchProduct(e: any, textString: string) {
    e.preventDefault()
    addCheckItem(textString)
  }
  async function addCheckItem(sku: string) {
    if (!sku) {
      return notify.error('Vui lòng nhập mã SKU hoặc sử dụng quyét mã')
    }
    if (map(orderItemReceiveds, 'sku').includes(sku)) {
      return notify.error('Sản phẩm đã tồn tại trong danh sách')
    }
    const item = find(buyingEntry?.orderItems, { sku })

    if (!item) {
      return notify.error('Sản phẩm không tồn tại trong BE')
    }

    if (!item.arrivingQuantity) {
      return notify.error('Số lượng nhận của sản phẩm nhỏ hơn 0')
    }

    setOrderItemReceiveds((current: TOrderItem[]) => {
      let clone = [...current]
      clone = clone.concat(item)
      return clone
    })
  }

  return (
    <IonPage>
      {buyingEntry ? (
        <>
          <Header
            title={`BE ${buyingEntry.buyingEntryId}`}
            hasBackButton={true}
            backUrl='/buying-entries/confirm-buying-entries'
          />
          <IonContent>
            <IonCard style={{ marginBottom: '25px' }}>
              <IonCardHeader>
                <IonCardSubtitle color='primary' style={{ fontSize: '17px' }}>
                  NHẬN HÀNG
                  <IonRow>
                    <IonCol
                      style={{
                        height: '10px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      }}
                    ></IonCol>
                  </IonRow>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <SearchBar
                  placeHolder='Nhập mã sản phẩm'
                  onSubmit={(e, textSeach) => handleSearchProduct(e, textSeach)}
                  hasScanner={true}
                  scanCallback={(data) => {
                    const sku = data.text
                    if (!sku) {
                      return notify.error('Quét thất bại')
                    }
                    addCheckItem(sku)
                  }}
                />
                {buyingEntry
                  ? map(orderItemReceiveds, (item, index) => {
                      return (
                        <CheckConfirmBuyingEntry
                          key={index + item._id}
                          item={item}
                        />
                      )
                    })
                  : null}
                {!orderItemReceiveds ? (
                  <b>Không có sản phẩm nào cần confirm</b>
                ) : null}
              </IonCardContent>
            </IonCard>

            <IonCard style={{ marginBottom: '25px' }}>
              <IonCardHeader>
                <IonCardSubtitle color='primary' style={{ fontSize: '17px' }}>
                  THANH TOÁN
                  <IonRow>
                    <IonCol
                      style={{
                        height: '10px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      }}
                    ></IonCol>
                  </IonRow>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    <IonLabel>
                      <strong>Tổng tiền thực trả:</strong>
                    </IonLabel>
                    <IonInput
                      style={{
                        marginTop: '5px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        height: '50%',
                      }}
                      disabled={noActualPayment}
                      type='number'
                      value={actualPayment}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setActualPayment(+e.detail.value)
                        }
                        setActualPayment(undefined)
                      }}
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonCheckbox
                      color='primary'
                      checked={noActualPayment}
                      onIonChange={(e) => {
                        const target = e.target as HTMLIonCheckboxElement
                        setNoActualPayment(() => {
                          return target.checked
                        })
                      }}
                    />{' '}
                    {'\u00A0'}
                    <IonLabel> Không trả tiền </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel>
                      <strong>Tổng tiền trên hóa đơn:</strong>
                    </IonLabel>
                    <IonInput
                      style={{
                        marginTop: '5px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        height: '50%',
                      }}
                      disabled={noBillPayment}
                      type='number'
                      value={billPayment}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setBillPayment(+e.detail.value)
                        }
                        setBillPayment(undefined)
                      }}
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonCheckbox
                      color='primary'
                      checked={noBillPayment}
                      onIonChange={(e) => {
                        const target = e.target as HTMLIonCheckboxElement
                        setNoBillPayment(() => {
                          return target.checked
                        })
                      }}
                    />{' '}
                    {'\u00A0'}
                    <IonLabel> Không có hóa đơn </IonLabel>
                  </IonCol>
                </IonRow>

                {noBillPayment && (
                  <IonRow>
                    <IonCol>
                      <IonLabel position='floating'>
                        <strong>Lý do không có hóa đơn</strong>
                      </IonLabel>
                      <IonTextarea
                        value={noBillReason}
                        style={{
                          border: '1px solid black',
                          borderRadius: '5px',
                          backgroundColor: '#FBFBFB',
                        }}
                        onIonChange={(e) => {
                          if (e.detail.value) {
                            return setNoBillReason(e.detail.value)
                          }
                          setNote('')
                        }}
                      />
                    </IonCol>
                  </IonRow>
                )}

                <IonRow>
                  <IonCol>
                    <IonLabel position='floating'>
                      <strong>Phương thức thanh toán</strong>
                    </IonLabel>
                    <IonSelect
                      name='paymentType'
                      style={{
                        marginTop: '5px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        height: '50%',
                      }}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setPaymentType(e.detail.value)
                        }
                        setPaymentType('')
                      }}
                    >
                      <IonSelectOption value='debt'>Công nợ</IonSelectOption>
                      <IonSelectOption value='transfer'>
                        Chuyển khoản
                      </IonSelectOption>
                      <IonSelectOption value='cash'>Tiền mặt</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol
                    style={{
                      height: '10px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                  ></IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel position='floating'>
                      <strong>Ảnh hóa đơn</strong>
                    </IonLabel>
                    <UploadImages
                      setImagesUpload={setBillImages}
                      imagesUpload={billImages}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel position='floating'>
                      <strong>Ảnh hàng hóa</strong>
                    </IonLabel>
                    <UploadImages
                      setImagesUpload={setVendorProductImages}
                      imagesUpload={vendorProductImages}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle color='primary' style={{ fontSize: '17px' }}>
                  THÔNG TIN KHÁC
                  <IonRow>
                    <IonCol
                      style={{
                        height: '10px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      }}
                    ></IonCol>
                  </IonRow>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol size='12' className='ion-text-left'>
                    <IonLabel position='floating'>
                      <strong>Ghi chú:</strong>
                    </IonLabel>
                    <IonTextarea
                      value={note}
                      style={{
                        border: '1px solid black',
                        borderRadius: '5px',

                        backgroundColor: '#FBFBFB',
                      }}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setNote(e.detail.value)
                        }
                        setNote('')
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='12' className='ion-text-left'>
                    <IonLabel position='floating'>
                      <strong>Nhân viên confirm:</strong>
                    </IonLabel>
                    <SearchBar
                      clean={true}
                      placeHolder='Nhập code người dùng'
                      onSubmit={(e, textSearch) => handleStaff(e, textSearch)}
                    />
                    {processStaffs.length > 0 &&
                      map(processStaffs, (item, index) => {
                        return (
                          <IonRow key={item._id}>
                            <IonCol size='12'>
                              <IonCol className='ion-text-left'>
                                <strong>
                                  {item.code} - {item.displayName}{' '}
                                </strong>
                              </IonCol>
                              <IonCol className='ion-text-right'>
                                <IonIcon
                                  size='medium'
                                  onClick={() => removeStaff(index)}
                                  icon={closeCircleSharp}
                                  color='danger'
                                />
                              </IonCol>
                            </IonCol>
                          </IonRow>
                        )
                      })}
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
            <IonRow style={{ margin: '20px 0' }}>
              <IonButton onClick={confirm} style={{ margin: 'auto' }}>
                Confirm
              </IonButton>
            </IonRow>
          </IonContent>
        </>
      ) : (
        <IonTitle>Có lỗi xảy ra 😥</IonTitle>
      )}
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
})
export default BuyingEntryDetail
