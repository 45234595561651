import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Redirect } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import PlTaskDetail from '../features/ConfirmPlTask/components/PlTaskDetail'
import ConfirmPlTask from '../features/ConfirmPlTask/page/ConfirmPlTask'
import ProductLocation from '../features/ProductLocation/page/ProductLocation'
import StoringEntry from '../features/StoringEntry/page/StoringEntry'
const Storing = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Redirect exact from='/storing' to='/storing/product-locations' />
        <PrivateRoute
          path='/storing/product-locations'
          exact
          component={ProductLocation}
        />
        <PrivateRoute
          path='/storing/confirm-pl-tasks'
          exact
          component={ConfirmPlTask}
        />
        <PrivateRoute
          path='/storing/confirm-pl-tasks/:plTaskId'
          exact
          component={PlTaskDetail}
        />
        <PrivateRoute
          path='/storing/storing-entries'
          exact
          component={StoringEntry}
        />
      </IonRouterOutlet>
    </IonPage>
  )
}

export default Storing
