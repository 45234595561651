import { CurrentShiftStore } from './../features/CurrentShift/store/currentShiftStore'
import { AuthenticationStore } from '../stores/authenticationStore'
import { ItemStore } from '../features/Item/store/itemStore'
import { RestockReportStore } from '../features/RestockReport/store/restockReportStore'
import { ProductLocationStore } from '../features/ProductLocation/store/productLocationStore'
import { StoringEntryStore } from '../features/StoringEntry/store/storingEntryStore'
import { PlTaskStore } from '../features/ConfirmPlTask/store/plTaskStore'
import { BuyingEntryStore } from '../features/BuyingEntries/store/buyingEntryStore'

export class RootStore {
  authenticationStore: AuthenticationStore
  restockReportStore: RestockReportStore
  itemStore: ItemStore
  productLocationStore: ProductLocationStore
  storingEntryStore: StoringEntryStore
  currentShiftStore: CurrentShiftStore
  plTaskStore: PlTaskStore
  buyingEntryStore : BuyingEntryStore
  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.restockReportStore = new RestockReportStore(this)
    this.itemStore = new ItemStore(this)
    this.productLocationStore = new ProductLocationStore(this)
    this.storingEntryStore = new StoringEntryStore(this)
    this.currentShiftStore = new CurrentShiftStore(this)
    this.plTaskStore = new PlTaskStore(this)
    this.buyingEntryStore = new BuyingEntryStore(this)
  }
}
