import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import RestockReportAPI from '../../../api/restockReportAPI'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import IItem from '../../Item/model/item'
import RestockReportDetailView from './RestockReportDetailView'

const RestockReportDetail = observer(() => {
  const history = useHistory()
  const { restockReportStore } = useContext(AppContext)
  const { restockReport, fetchById, setRestockReport } = restockReportStore

  const [waiting, setWaiting] = useState(false)
  const match = useRouteMatch()
  const notify = useNotify()
  function viewProductDetail(productId: String) {
    const restockReportId = restockReport?._id
    history.push('/restock-reports/' + restockReportId + '/view/' + productId, {
      direction: 'none',
    })
  }

  const quickCountMobile = async (item: IItem, quantity: number) => {
    if (restockReport) {
      setWaiting(true)
      await RestockReportAPI.countMobile(
        restockReport._id,
        item.product._id,
        quantity
      )
        .then(({ restockReport }) => {
          setRestockReport(restockReport)
        })
        .catch((err) => {
          return notify.errorFromServer(err)
        })
        .finally(() => {
          setWaiting(false)
        })
    }
  }

  const restockReportId = get(match, 'params.restockReportId')

  useEffect(() => {
    let subcribe = true
    async function getRestockReportDetail() {
      if (restockReportId) {
        setWaiting(true)
        await fetchById(restockReportId)
          .then((restockReport) => {
            setRestockReport(restockReport)
          })
          .catch((err) => {
            notify.errorFromServer(err)
          })
          .finally(() => {
            setWaiting(false)
          })
      }
    }
    if (subcribe) {
      getRestockReportDetail()
    }

    return () => {
      subcribe = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restockReportId])

  return (
    <RestockReportDetailView
      restockReport={restockReport}
      viewProductDetail={viewProductDetail}
      waiting={waiting}
      quickCountMobile={quickCountMobile}
    />
  )
})
export default RestockReportDetail
