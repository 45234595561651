import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'

import UploadImageButton from './UploadImageButton'
import Gallery from './Gallery'
import { IonLabel } from '@ionic/react'
import { filter } from 'lodash'
import { IImageUpload } from '../pages/FeedbackView'
import ENV from '../env/env'

interface Props {
  setImagesUpload: Dispatch<SetStateAction<IImageUpload[]>>
  imagesUpload: IImageUpload[]
  label?: string
}

const UploadImages = ({ setImagesUpload, imagesUpload, label }: Props) => {
  const removeImage = async (image: IImageUpload) => {
    const _imageUrls = filter(
      imagesUpload,
      (imageUpload) => imageUpload.link !== image.link
    )
    setImagesUpload(_imageUrls)

    try {
      const result = await axios.delete(
        `${ENV.IMAGE_WORKER_URL}/remove-image-s3?image=${JSON.stringify(
          image
        )}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )

      const uploadedImage = result.data.uploadedImage[0]
      return uploadedImage
    } catch (error) {}
  }

  return (
    <div>
      <IonLabel>{label}</IonLabel>
      <Gallery
        images={imagesUpload}
        removeImage={removeImage}
        buttonAddImage={<UploadImageButton setImagesUpload={setImagesUpload} />}
      />
    </div>
  )
}

export default UploadImages
