import {
  every,
  find,
  findIndex,
  get,
  isEqual,
  isNull,
  isUndefined,
  slice,
} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import RestockReportAPI from '../../../api/restockReportAPI'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import RestockReport from '../../RestockReport/model/restock-report'
import Item, { IItem, Action } from '../model/item'
import ItemDetailView from './ItemDetailView'

const ItemDetail = observer(() => {
  const { itemStore, restockReportStore } = useContext(AppContext)
  const {
    restockReport,
    countMobile,
    fetchById,
    setRestockReport,
  } = restockReportStore

  let { item, setItem } = itemStore
  const history = useHistory()
  const notify = useNotify()
  const match = useRouteMatch()
  const [waiting, setWaiting] = useState(false)
  const productId = get(match, 'params.productId')
  const restockReportId = get(match, 'params.restockReportId')

  useEffect(() => {
    let subcribe = true
    if (subcribe) {
      ;(async () => {
        if (!restockReport || restockReportId !== restockReport._id) {
          setWaiting(true)
          await fetchById(restockReportId)
            .catch((err) => {
              notify.errorFromServer(err)
            })
            .finally(() => {
              setWaiting(false)
            })
        }
        const _item = find(restockReport?.items, (item) => {
          return item?.product?._id === productId
        })
        if (_item) {
          setItem(_item)
        }
      })()
    }
    return () => {
      subcribe = false
    }
  }, [productId, restockReportId])

  function isCountDone(restockReport: RestockReport | undefined) {
    return every(restockReport?.items, (item) => {
      return item.takenQuantity
    })
  }

  function nextNotTakenItem(
    item: IItem,
    restockReport: RestockReport | undefined
  ): IItem | undefined {
    const currentIndex = findIndex(restockReport?.items, (_item: Item) => {
      return isEqual(_item.product._id, item.product._id)
    })

    const cloneItems = slice(restockReport?.items, currentIndex)
    const _item = find(cloneItems, (item: Item) => {
      return isUndefined(item.takenQuantity) || isNull(item.takenQuantity)
    })

    return _item
  }

  function handleChangeItem(item: IItem, action: Action) {
    const currentIndex = findIndex(restockReport?.items, (_item: Item) => {
      return isEqual(_item.product._id, item.product._id)
    })
    if (action === Action.Next) {
      const nextItem = restockReport?.items[currentIndex + 1]
      if (nextItem) {
        history.push(
          `/restock-reports/${restockReportId}/view/${nextItem.product?._id}`
        )
      }
    } else {
      const backItem = restockReport?.items[currentIndex - 1]
      if (backItem) {
        history.push(
          `/restock-reports/${restockReportId}/view/${backItem.product?._id}`
        )
      }
    }
  }
  async function handleCountMobile(item: IItem, quantity: number) {
    if (restockReport) {
      setWaiting(true)
      await RestockReportAPI.countMobile(
        restockReport._id,
        item.product._id,
        quantity
      )
        .then(({ item }) => {
          setItem(item)
          const itemIndex = findIndex(restockReport?.items, (item: Item) => {
            return item.product._id === productId
          })

          if (restockReport) {
            restockReport.items[itemIndex] = item
            setRestockReport(restockReport)
            if (isCountDone(restockReport)) {
              return notify.info('Bạn đã lấy hết sku của restock hiện tại')
            }
          }

          const nextItem: IItem | undefined = nextNotTakenItem(
            item,
            restockReport
          )

          if (nextItem) {
            history.push(
              `/restock-reports/${restockReportId}/view/${nextItem.product?._id}`
            )
          }
        })
        .catch((err) => {
          return notify.errorFromServer(err)
        })
        .finally(() => {
          setWaiting(false)
        })
    }
  }
  return (
    item && (
      <ItemDetailView
        waiting={waiting}
        item={item}
        handleCountMobile={handleCountMobile}
        handleChangeItem={handleChangeItem}
      />
    )
  )
})
export default ItemDetail
