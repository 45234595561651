import { RootStore } from '../../../models/root-store'
import { IProductLocation } from '../model/productLocation'
import { action, makeObservable, observable } from 'mobx'
import { uniqBy, findIndex } from 'lodash'

export class ProductLocationStore {
  rootStore: RootStore
  productLocations: IProductLocation[]
  locationCodes: string[]
  isLoading: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.productLocations = []
    this.locationCodes = []
    this.isLoading = false

    makeObservable(this, {
      productLocations: observable,
      locationCodes: observable,
      isLoading: observable,
      setProductLocations: action,
      addProductLocations: action,
      getProductLocations: action,
    })
  }

  setIsLoading = (status: boolean) => {
    this.isLoading = status
  }

  setProductLocations = (productLocations: IProductLocation[]) => {
    this.productLocations = productLocations
  }

  addProductLocations = (productLocations: IProductLocation[]) => {
    this.productLocations = uniqBy(
      [...this.productLocations, ...productLocations],
      '_id'
    )
  }

  updateProductLocation = (productLocation: IProductLocation) => {
    const index = findIndex(this.productLocations, { _id: productLocation._id })

    this.productLocations[index].lastInventoryLocation =
      productLocation.lastInventoryLocation
  }

  getProductLocations = () => {
    return this.productLocations
  }

  setLocationCodes = (codes: string[]) => {
    return (this.locationCodes = codes)
  }
}
