import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
} from '@ionic/react'
import { map } from 'lodash'
import moment from 'moment'
import { IBuyingEntry } from '../model/buyingEntries'
type TProps = {
  buyingEntry: IBuyingEntry
  redirectBuyingEntryDetail: (buyingEntry: IBuyingEntry) => void
}

const BuyingEntryItem = ({
  buyingEntry,
  redirectBuyingEntryDetail,
}: TProps) => {
  const { orderItems, destination, vendor } = buyingEntry || {}
  return (
    <IonCard color='light'>
      <IonCardContent>
        <IonRow>
          <IonCol className='ion-text-left' size='6'>
            <strong>
              {moment(buyingEntry.expectedArrivalDate).format('DD/MM/YYYY')}
            </strong>
          </IonCol>
          <IonCol className='ion-text-right' size='6'>
            <strong>ID #{buyingEntry.buyingEntryId}</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='9'>
            <strong>{vendor.name} </strong>
          </IonCol>

          <IonCol className='ion-text-right' size='3'>
            <strong>{destination.code} </strong>
          </IonCol>
        </IonRow>
        <hr></hr>
        <IonRow>
          {map(orderItems, (item, index) => {
            return (
              <IonCol key={item._id} size='12'>
                {item?.sku} - {item?.description}
              </IonCol>
            )
          })}
        </IonRow>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          <IonCol className='ion-text-center ion-text-uppercase'>
            <IonButton onClick={() => redirectBuyingEntryDetail(buyingEntry)}>
              Confirm
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default BuyingEntryItem
