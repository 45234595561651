import apiClient from '../libs/api-service'
const FeedbackAPI = {
  postFeedback: async (rate: number, content: string) => {
    const os = function () {
      const module = {
        options: [],
        header: [
          navigator.platform,
          navigator.userAgent,
          navigator.appVersion,
          navigator.vendor,
        ],
        dataos: [
          { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
          { name: 'Windows', value: 'Win', version: 'NT' },
          { name: 'iPhone', value: 'iPhone', version: 'OS' },
          { name: 'iPad', value: 'iPad', version: 'OS' },
          { name: 'Kindle', value: 'Silk', version: 'Silk' },
          { name: 'Android', value: 'Android', version: 'Android' },
          { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
          { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
          { name: 'Macintosh', value: 'Mac', version: 'OS X' },
          { name: 'Linux', value: 'Linux', version: 'rv' },
          { name: 'Palm', value: 'Palm', version: 'PalmOS' },
        ],
      }
      const agent = module.header.join(' ')
      const os = matchItems(agent, module.dataos)
      return os
    }

    const matchItems = function (string: string, data: any) {
      let i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i')
        match = regex.test(string)
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
          matches = string.match(regexv)
          version = ''
          if (matches) {
            if (matches[1]) {
              matches = matches[1]
            }
          }
          if (matches) {
            matches = String(matches)
            matches = matches.split(/[._]+/)
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.'
              } else {
                version += matches[j]
              }
            }
          } else {
            version = '0'
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          }
        }
      }
      return { name: 'unknown', version: 0 }
    }
    const feedback = await apiClient.post(`/api/feedbacks`, {
      rate,
      content,
      userOs: os().name + ' - ' + os().version,
      appId: 'warehouseApp',
    })
    return feedback
  },
}

export default FeedbackAPI
