import { IonList } from '@ionic/react'
import { map } from 'lodash'
import { useHistory } from 'react-router'
import { IPlTask } from '../model/plTask'
import PlTaskItem from './PlTaskItem'

type TProps = {
  plTasks: IPlTask[]
}

const PlTaskList = ({ plTasks }: TProps) => {
  const history = useHistory()
  function redirectPlTaskDetail(plTask: IPlTask) {
    history.push(`/storing/confirm-pl-tasks/${plTask._id}`)
  }
  return (
    <>
      <IonList>
        {map(plTasks, (plTask) => (
          <PlTaskItem
            key={plTask._id}
            plTask={plTask}
            redirectPlTaskDetail={redirectPlTaskDetail}
          />
        ))}
      </IonList>
    </>
  )
}

export default PlTaskList
