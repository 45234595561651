import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonInput,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
} from '@ionic/react'
import { get, map, some } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import ProductAPI from '../../../api/productAPI'
import Header from '../../../components/Header'
import SearchBar from '../../../components/SearchBar'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import { ProductWithWeight } from '../../../models/product'
import PlTaskAPI from '../api/plTaskAPI'
import { IPlTask } from '../model/plTask'
import CheckItem from './CheckItem'
const PlTaskDetail = observer(() => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [plTask, setPlTask] = useState<IPlTask | null>(null)
  const [checkedItems, setCheckedItems] = useState<ProductWithWeight[]>([])

  const [confirmManufactureDate, setManufactureDate] = useState<string>()
  const [confirmExpiryDate, setExpiredDate] = useState<string>()
  const [noDate, setNoDate] = useState<boolean | undefined>(
    () => plTask?.noDate
  )

  const [confirmQuantity, setConfirmQuantity] = useState<number>()
  const [badQuantity, setBadQuantity] = useState<number>()
  const [badReason, setBadReason] = useState<string>()

  const params = useParams()
  const plTaskId = get(params, 'plTaskId')
  const notify = useNotify()
  const history = useHistory()

  useEffect(() => {
    let isFetch = true
    const fetchPlTask = async (plTaskId: string) => {
      setLoading(true)
      const _plTask = await PlTaskAPI.findOne(plTaskId)
        .catch((err) => {
          return notify.errorFromServer(err)
        })
        .finally(() => {
          setLoading(false)
        })
      setPlTask(_plTask)
    }
    if (plTaskId && isFetch) {
      fetchPlTask(plTaskId)
    }
    return () => {
      isFetch = false
    }
  }, [plTaskId])

  async function addCheckItem(sku: string) {
    if (!sku) {
      return notify.error('Vui lòng nhập mã SKU hoặc sử dụng quyét mã')
    }

    setLoading(true)
    await ProductAPI.search(sku)
      .then((product) => {
        setCheckedItems((current: ProductWithWeight[]) => {
          let clone = [...current]
          clone = clone.concat(product)
          return clone
        })
      })
      .catch((err) => {
        return notify.errorFromServer(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleSearchProduct(e: any, textString: string) {
    e.preventDefault()
    addCheckItem(textString)
  }

  const removeCheckedItem = (index: number) => {
    setCheckedItems((current: ProductWithWeight[]) => {
      let clone = [...current]
      clone.splice(index, 1)
      return clone
    })
  }

  const { plTaskStore } = useContext(AppContext)
  const { setReload } = plTaskStore
  const confirm = async function () {
    if (plTask) {
      Object.assign(plTask, {
        checkedItems,
        confirmManufactureDate,
        confirmExpiryDate,
        noDate,
        confirmQuantity,
        badQuantity,
        badReason,
      })

      if (plTask.plTaskType !== 'adhoc' && plTask.sku.match(/^B\d+/)) {
        if (
          plTask.checkedItems?.length &&
          plTask.checkedItems?.length < 3 &&
          plTask.confirmQuantity !== plTask.checkedItems?.length
        ) {
          return notify.error(
            'Chưa nhập đủ danh sách sản phẩm kiểm tra ngẫu nhiên'
          )
        }

        if (
          !plTask.noDate &&
          (!plTask.confirmManufactureDate || !plTask.confirmExpiryDate)
        ) {
          return notify.error('Bạn chưa nhập NSX và HSD')
        }

        if (plTask.noDate && plTask.expiryDate) {
          return notify.error('Lô này bắt buộc phải có hạn sử dụng.')
        }

        if (
          moment(plTask.confirmExpiryDate)
            .startOf('day')
            .diff(moment().startOf('day')) < 0
        ) {
          return notify.error(
            'HSD phải lớn hơn ngày hôm nay. Vui lòng kiểm tra lại'
          )
        }
        if (
          some(plTask.checkedItems, (item) => {
            return !item.maxWeightAllow || !item.minWeightAllow
          })
        ) {
          return notify.error(
            'Có sản phẩm chưa có khối lượng tối đa hoặc tối thiểu. Vui lòng liên hệ với quản lý ĐG'
          )
        }

        if (
          some(plTask.checkedItems, (item) => {
            if (item.minWeightAllow && item.maxWeightAllow && !item.weight) {
              return true
            }
          })
        ) {
          return notify.error('Có sản phẩm chưa nhập khối lượng kiểm tra')
        }

        if (some(plTask.checkedItems, 'wrongWeight')) {
          return notify.error(
            'Có sản phẩm sai khối lượng đóng gói. Vui lòng liên hệ với quản lý ĐG'
          )
        }

        if (some(plTask.checkedItems, 'wrongLabel')) {
          return notify.error(
            'Có sản phẩm sai mã vạch. Vui lòng liên hệ với quản lý ĐG'
          )
        }

        if (!plTask.confirmQuantity) {
          return notify.error('Chưa nhập số lượng sản phẩm nhập kho')
        }

        setLoading(true)
        await PlTaskAPI.confirm(plTask)
          .catch((err) => {
            return notify.errorFromServer(err)
          })
          .catch(() => {
            setLoading(false)
          })
        setReload(true)
        notify.success('Confirm thành công')
        history.goBack()
      }
    }
  }

  return (
    <IonPage>
      {plTask ? (
        <>
          <Header
            title={`#${plTask.plTaskId}`}
            hasBackButton={true}
            backUrl='/storing/confirm-pl-tasks'
          />
          <IonContent>
            <h4 style={{ textAlign: 'center' }}>
              {plTask.sku} - {plTask.description}
            </h4>

            <IonCard style={{ marginBottom: '25px' }}>
              <IonCardHeader>
                <IonCardSubtitle color='primary'>
                  CHECK KHỐI LƯỢNG
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <SearchBar
                  placeHolder='Nhập 3 sản phẩm'
                  onSubmit={(e, textSeach) => handleSearchProduct(e, textSeach)}
                  hasScanner={true}
                  scanCallback={(data) => {
                    const sku = data.text
                    if (!sku) {
                      return notify.error('Quét thất bại')
                    }
                    addCheckItem(sku)
                  }}
                />
                {checkedItems.length
                  ? map(checkedItems, (item, index) => {
                      return (
                        <CheckItem
                          key={index + item._id}
                          item={item}
                          index={index + 1}
                          plTask={plTask}
                          removeCheckedItem={() => removeCheckedItem(index)}
                        />
                      )
                    })
                  : null}
              </IonCardContent>
            </IonCard>

            <IonCard style={{ marginBottom: '25px' }}>
              <IonCardHeader>
                <IonCardSubtitle color='primary'>
                  CHECK DATE SẢN PHẨM
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCheckbox
                    color='primary'
                    checked={noDate}
                    onIonChange={(e) => {
                      const target = e.target as HTMLIonCheckboxElement
                      setNoDate(() => {
                        return target.checked
                      })
                    }}
                  />{' '}
                  {'\u00A0'}
                  <IonLabel>Không có hạn </IonLabel>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel position='floating'>
                      <strong>NSX:</strong>
                    </IonLabel>
                    <IonDatetime
                      pickerFormat={'DD/MM/YYYY'}
                      disabled={noDate}
                      style={{
                        border: '1px solid black',
                        borderRadius: '6px',
                        height: '60%',
                      }}
                      displayFormat='MM/DD/YYYY'
                      value={confirmManufactureDate}
                      onIonChange={(e) => {
                        setManufactureDate(e.detail.value!)
                      }}
                    />
                  </IonCol>

                  <IonCol>
                    <IonLabel position='floating'>
                      <strong>HSD:</strong>
                    </IonLabel>
                    <IonDatetime
                      pickerFormat={'DD/MM/YYYY'}
                      disabled={noDate}
                      style={{
                        border: '1px solid black',
                        borderRadius: '6px',
                        height: '60%',
                      }}
                      displayFormat='MM/DD/YYYY'
                      value={confirmExpiryDate}
                      onIonChange={(e) => {
                        setExpiredDate(e.detail.value!)
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>
                      <strong>
                        Số tháng sử dụng sản phẩm:{' '}
                        <IonText color='primary'>
                          <strong>{get(plTask, 'product.expiryPeriod')}</strong>
                        </IonText>
                      </strong>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle color='primary'>
                  CHECK SỐ LƯỢNG/CHẤT LƯỢNG
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    <strong>
                      Số lượng DG: {'\u00A0'}
                      <IonText color='primary'>
                        {plTask.deliverQuantity}
                      </IonText>
                    </strong>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='8'>
                    <IonLabel>
                      <strong>Số lượng thực nhập KHO:</strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol size='4'>
                    <IonInput
                      style={{
                        borderBottom: '1px solid #333',
                        height: '25px',
                        padding: '2px ',
                      }}
                      placeholder='Nhập số lượng'
                      type='number'
                      value={confirmQuantity}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setConfirmQuantity(+e.detail.value)
                        }
                        setConfirmQuantity(undefined)
                      }}
                    ></IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={'8'}>
                    <IonLabel>
                      <strong>Số lượng không đạt chất lượng:</strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol size={'4'}>
                    <IonInput
                      style={{
                        borderBottom: '1px solid #333',
                        height: '25px',
                        padding: '2px ',
                      }}
                      placeholder='Nhập số lượng'
                      type='number'
                      value={badQuantity}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          return setBadQuantity(+e.detail.value)
                        }
                        setBadQuantity(undefined)
                        setBadReason('')
                      }}
                    ></IonInput>
                  </IonCol>
                </IonRow>
                {badQuantity ? (
                  <IonRow>
                    <IonCol>
                      <IonLabel>
                        <strong>Lý do không đạt:</strong>
                      </IonLabel>
                      <IonTextarea
                        value={badReason}
                        debounce={500}
                        onIonChange={(e) => {
                          if (e.detail.value) {
                            return setBadReason(e.detail.value)
                          }
                          setBadReason('')
                        }}
                        style={{ border: '1px solid grey' }}
                      />
                    </IonCol>
                  </IonRow>
                ) : null}
              </IonCardContent>
            </IonCard>
            <IonRow style={{ margin: '20px 0' }}>
              <IonButton onClick={confirm} style={{ margin: 'auto' }}>
                Confirm
              </IonButton>
            </IonRow>
          </IonContent>
        </>
      ) : (
        <IonTitle>Có lỗi xảy ra 😥</IonTitle>
      )}
      <IonLoading isOpen={isLoading} />
    </IonPage>
  )
})
export default PlTaskDetail
