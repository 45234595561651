import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
} from '@ionic/react'
import { IStoringEntry } from '../model/storingEntry'

type TProps = {
  storingEntry: IStoringEntry
  onClickEdit: (storingEntry: IStoringEntry) => void
}

const StoringEntryItem = ({ storingEntry, onClickEdit }: TProps) => {
  const { description, sku, storingEntryId } = storingEntry

  return (
    <IonCard color='light'>
      <IonCardContent>
        <IonRow
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <b>{storingEntryId}</b>
        </IonRow>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          <IonCol size='8'>
            {sku} - {description}
          </IonCol>
          <IonCol className='ion-text-right'>
            <IonButton onClick={() => onClickEdit(storingEntry)}>Sửa</IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default StoringEntryItem
