import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react'
import { map } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import RestockReportAPI from '../../../api/restockReportAPI'
import Header from '../../../components/Header'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import RestockReport from '../model/restock-report'

const RestockReportsView: React.FC = observer(() => {
  const history = useHistory()
  const [restockReports, setRestockReports] = useState<[] | RestockReport[]>([])
  const { currentShiftStore } = useContext(AppContext)
  async function goToRestockReport(_id: String) {
    history.push('/restock-reports/detail/' + _id, { direction: 'none' })
  }

  const [waiting, setWaiting] = useState(true)
  const notify = useNotify()
  const { currentShift } = currentShiftStore
  useEffect(() => {
    let subcribe = true

    async function getRestockReports() {
      let params = {}
      if (currentShift) {
        const { outlet } = currentShift
        params = { outlet: outlet._id || outlet }
      }
      const restockReports = await RestockReportAPI.getAll(params).catch(
        (err) => {
          notify.errorFromServer(err)
        }
      )
      setRestockReports(restockReports)
    }
    if (subcribe) {
      getRestockReports().finally(() => {
        setWaiting(false)
      })
    }
    return () => {
      subcribe = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShift])

  return (
    <RestockReportsViewLoading
      restockReports={restockReports}
      goToRestockReport={goToRestockReport}
      waiting={waiting}
    />
  )
})

type RestockReportsProps = {
  restockReports: any
  goToRestockReport: any
  waiting: boolean
}
const RestockReportsViewLoading = React.memo(function ({
  restockReports,
  goToRestockReport,
  waiting,
}: RestockReportsProps) {
  return (
    <>
      <IonPage>
        <Header title='Restocks' />
        <IonContent fullscreen className='ion-padding ion-text-center'>
          <IonGrid>
            {map(restockReports, (restockReport) => {
              return (
                <IonCard
                  key={restockReport.restockReportId}
                  onClick={() => goToRestockReport(restockReport._id)}
                >
                  <IonItem>
                    <IonLabel
                      className='ion-text-center'
                      style={{ fontWeight: 700 }}
                    >
                      <IonRow>
                        <IonCol>{restockReport.restockReportId}</IonCol>
                        <IonCol>
                          {restockReport.warehouseRestock.code}
                          {' => '}
                          {restockReport.outlet.code}
                        </IonCol>
                      </IonRow>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent style={{ fontWeight: 500 }}>
                    {'Đã lấy'} {restockReport.takenQuantity || 0}/
                    {restockReport.totalQuantity || 0} sku
                  </IonCardContent>
                </IonCard>
              )
            })}
          </IonGrid>
        </IonContent>
      </IonPage>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={waiting}
        message={'Đang tải...'}
      />
    </>
  )
})

export default RestockReportsView
