import { findIndex } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { RootStore } from '../../../models/root-store'
import { IStoringEntry } from '../model/storingEntry'

export class StoringEntryStore {
  rootStore: RootStore
  storingEntries: IStoringEntry[]
  locationCodes: string[]
  isLoading: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.storingEntries = []
    this.locationCodes = []
    this.isLoading = false

    makeObservable(this, {
      storingEntries: observable,
      locationCodes: observable,
      isLoading: observable,
      setStoringEntries: action,
      getStoringEntries: action,
      updateStoringLocation: action,
    })
  }

  setIsLoading = (status: boolean) => {
    this.isLoading = status
  }

  setStoringEntries = (storingEntries: IStoringEntry[]) => {
    this.storingEntries = storingEntries
  }

  getStoringEntries = () => {
    return this.storingEntries
  }
  updateStoringLocation = (storingEntry: IStoringEntry) => {
    const index = findIndex(this.storingEntries, {
      _id: storingEntry._id,
    })
    this.storingEntries.splice(index, 1)
  }
}
