import { BarcodeScanner } from '@ionic-native/barcode-scanner'
import { IonButton, IonIcon } from '@ionic/react'
import { scan } from 'ionicons/icons'
import React from 'react'
import useNotify from '../hook/useNotify'
const BarScanner = ({ text, callback }: { text?: string; callback: any }) => {
  const notify = useNotify()
  const openScanner = async () => {
    try {
      const data = await BarcodeScanner.scan({ showTorchButton: true })
      return callback(data)
    } catch (error) {
      return notify.errorFromServer(error)
    }
  }
  return (
    <IonButton onClick={openScanner} color='medium'>
      {text || ''}
      <IonIcon icon={scan} />
    </IonButton>
  )
}

export default BarScanner
