import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
} from '@ionic/react'
import moment from 'moment'
import { IPlTask } from '../model/plTask'
type TProps = {
  plTask: IPlTask
  redirectPlTaskDetail: (plTask: IPlTask) => void
}

const PlTaskItem = ({ plTask, redirectPlTaskDetail }: TProps) => {
  const { description, sku } = plTask

  return (
    <IonCard color='light'>
      <IonCardContent>
        <IonRow>
          <IonCol className='ion-text-left' size='6'>
            <strong>
              {moment(plTask.delivered).format('DD/MM/YYYY')}
              <br />
              {moment(plTask.delivered).format('hh:mm')}
            </strong>
          </IonCol>
          <IonCol className='ion-text-right' size='6'>
            <strong>ID #{plTask.plTaskId}</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='8'>
            {sku} - {description}
          </IonCol>
          <IonCol className='ion-text-right' size='4'>
            <strong>Kho {plTask.destination.code}</strong>
          </IonCol>
        </IonRow>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          <IonCol>
            <b>SL:{plTask.deliverQuantity}</b>
          </IonCol>
          <IonCol className='ion-text-right'>
            <IonButton onClick={() => redirectPlTaskDetail(plTask)}>
              Confirm
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default PlTaskItem
