import { IonIcon, IonImg, IonModal } from '@ionic/react'
import { removeCircle } from 'ionicons/icons'
import { map } from 'lodash'
import { useState } from 'react'
import { IImageUpload } from '../pages/FeedbackView'
import './Gallery.css'

interface Props {
  images: IImageUpload[]
  buttonAddImage?: JSX.Element
  removeImage: (image: IImageUpload) => void
}

const Gallery = ({ images, buttonAddImage, removeImage }: Props) => {
  const [imageVisible, setImageVisible] = useState<string>('')

  const [showModal, setShowModal] = useState<boolean>(false)

  const onClickSmallImage = (link: string) => {
    setImageVisible(link)
    setShowModal(true)
  }

  const dismiss = () => {
    setShowModal(false)
  }

  return (
    <div className='list-small-image'>
      {map(images, (image) => (
        <div key={image.link} style={{ position: 'relative' }}>
          <IonIcon
            className='button-remove-image'
            icon={removeCircle}
            onClick={() => removeImage(image)}
          />

          <IonImg
            className='image--small'
            src={image.link}
            onClick={() => onClickSmallImage(image.link)}
          />
        </div>
      ))}

      {buttonAddImage}

      <IonModal
        isOpen={showModal}
        cssClass='modal-image'
        onDidDismiss={dismiss}
      >
        <span className='button-close' onClick={dismiss}>
          +
        </span>
        <IonImg src={imageVisible} className='image--large' onClick={dismiss} />
      </IonModal>
    </div>
  )
}

export default Gallery
