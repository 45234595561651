import apiClient from '../libs/api-service'

const ProductAPI = {
  search: async (sku: string) => {
    const product = await apiClient.get(`/api/products/${sku}`).catch((err) => {
      throw err
    })
    return product
  },
}

export default ProductAPI
