import { IPlTask } from './../model/plTask'
import apiClient from '../../../libs/api-service'

const PlTaskAPI = {
  getAll: async ({ shift, outlet }: { shift?: String; outlet?: String }) => {
    const plTasks = await apiClient.get('/api/pl-tasks', {
      params: { shift, destination: outlet, status: 'delivered' },
    })
    return plTasks
  },
  findOne: async (plTaskId: string) => {
    return apiClient.get(`/api/pl-tasks/${plTaskId}`)
  },
  confirm: async (plTask: IPlTask) => {
    return apiClient.put(`/api/pl-tasks/${plTask._id}/confirm`, {
      ...plTask,
    })
  },
}

export default PlTaskAPI
