import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import ProductLocationAPI from '../../../api/productLocationAPI'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import { IProductLocation } from '../model/productLocation'
import ProductLocationView from './ProductLocationView'

const ProductLocation = observer(() => {
  const { productLocationStore, currentShiftStore } = useContext(AppContext)
  const notify = useNotify()
  const history = useHistory()
  const {
    addProductLocations,
    productLocations,
    isLoading,
    setIsLoading,
  } = productLocationStore
  const { currentShift } = currentShiftStore

  useEffect(() => {
    if (!currentShift) {
      notify.info('Bạn cần đăng nhập ca trực để load dữ liệu')
    }
  })

  useEffect(() => {
    let subcribe = true
    if (subcribe && currentShift) {
      const getProductLocation = async () => {
        setIsLoading(true)

        const _productLocations: IProductLocation[] = await ProductLocationAPI.get()

        if (_productLocations) {
          addProductLocations(_productLocations)
        }
        setIsLoading(false)
      }
      getProductLocation()
    }
    return () => {
      subcribe = false
    }
  }, [])

  return (
    <ProductLocationView
      productLocations={productLocations}
      isLoading={isLoading}
    />
  )
})

export default ProductLocation
