import apiClient from '../libs/api-service'

const ProductLocationAPI = {
  get: async () => {
    const productLocations = await apiClient.get('/api/product-locations')
    return productLocations
  },

  getLocationCodes: async ({ outlet }: { outlet?: any }) => {
    const locationCodes = await apiClient.get('/api/inventory-location-codes', {
      params: { outlet },
    })
    return locationCodes
  },

  updateProductLocation: async (productId: string, locationCode: string) => {
    const product = await apiClient.put(
      `/api/product/${productId}/update-location`,
      { locationCode }
    )

    return product
  },
}

export default ProductLocationAPI
