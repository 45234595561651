import { get, map } from 'lodash'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
} from '@ionic/react'

import { IProductLocation } from '../model/productLocation'

type TProps = {
  productLocation: IProductLocation
  onClickEdit: (productLocation: IProductLocation) => void
}

const ProductLocationItem = ({ productLocation, onClickEdit }: TProps) => {
  const { description, sku } = productLocation
  const code = get(productLocation, 'lastInventoryLocation.code')

  const warehouseOutletCodes = ['D1', 'D2', 'D4']

  return (
    <IonCard color='light'>
      <IonCardContent>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          <IonCol size='8'>
            {sku} - {description}
          </IonCol>
          <IonCol size='4'>
            {map(warehouseOutletCodes, (outletCode) => {
              const count = get(productLocation, ['stockMap', outletCode])
              return count ? (
                <IonRow key={outletCode}>
                  <IonCol className='ion-text-right'>
                    Tồn {outletCode}: {count}
                  </IonCol>
                </IonRow>
              ) : null
            })}
          </IonCol>
        </IonRow>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          <IonCol>
            <b>{code}</b>
          </IonCol>
          <IonCol className='ion-text-right'>
            <IonButton onClick={() => onClickEdit(productLocation)}>
              Sửa
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default ProductLocationItem
