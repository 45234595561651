import { every, get, reject } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'
import BuyingEntriesAPI from '../api/buyingEntriesAPI'
import { IBuyingEntry } from '../model/buyingEntries'
import ConfirmBuyingEntryView from './ConfirmBuyingEntryView'

const ConfirmBuyingEntries = observer(() => {
  const { currentShiftStore, buyingEntryStore } = useContext(AppContext)
  const { isReload, setReload } = buyingEntryStore
  const notify = useNotify()
  const history = useHistory()

  const [buyingEntries, setBuyingEntries] = useState<IBuyingEntry[] | []>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const { currentShift } = currentShiftStore

  async function fetchBuyingEntries({
    shift,
    outlet,
  }: {
    shift: string
    outlet: string
  }) {
    setLoading(true)
    const _buyingEntries = await BuyingEntriesAPI.getAll({ shift, outlet })
      .catch((err) => {
        return notify.errorFromServer(err)
      })
      .finally(() => {
        setLoading(false)
      })
    const __buyingEntries = reject(_buyingEntries, (buyingEntry) => {
      if (
        get(buyingEntry, 'orderItems.length') > 0 &&
        every(buyingEntry.orderItems, (item) => {
          if (get(item, 'arrivingQuantity') === 0) {
            return true
          }
        })
      ) {
        return true
      }
    })
    setReload(false)
    setBuyingEntries(__buyingEntries)
  }

  useEffect(() => {
    if (!currentShift) {
      notify.info('Bạn cần đăng nhập ca trực để load dữ liệu')
      setBuyingEntries([])
    }
  }, [currentShift])

  useEffect(() => {
    let isFetch = true

    if (currentShift && isFetch && isReload) {
      const { shift, outlet } = currentShift
      fetchBuyingEntries({
        shift: shift,
        outlet: outlet._id || String(outlet),
      })
    }
    return () => {
      isFetch = false
    }
  }, [isReload])

  return (
    <ConfirmBuyingEntryView
      buyingEntries={buyingEntries}
      isLoading={isLoading}
    />
  )
})

export default ConfirmBuyingEntries
