import {
  IonBadge,
  IonCol,
  IonIcon,
  IonInput,
  IonRow,
  IonText,
} from '@ionic/react'
import { closeCircleSharp } from 'ionicons/icons'
import React, { useRef, useState } from 'react'
import { ProductWithWeight } from '../../../models/product'
import { IPlTask } from '../model/plTask'
import { isExists } from '../../../utils/helper'
function CheckItem({
  item,
  index,
  plTask,
  removeCheckedItem,
}: {
  item: ProductWithWeight
  index: number
  plTask: IPlTask
  removeCheckedItem: () => void
}) {
  const checkSKU = item.sku === plTask.sku
  const [wrongWeight, setWrongWeight] = useState<boolean | null>(null)
  const inputRef = useRef<any>()

  function onChangeQuantity(item: ProductWithWeight) {
    item.weight = +inputRef.current.value

    if (!isFinite(item.weight)) {
      setWrongWeight(null)
      item.wrongWeight = null
      return
    }
    if (!item.minWeightAllow || !item.maxWeightAllow) {
      setWrongWeight(null)
      item.wrongWeight = null
      return
    }
    if (
      item.weight &&
      (item.maxWeightAllow < item.weight || item.minWeightAllow > item.weight)
    ) {
      setWrongWeight(true)
      item.wrongWeight = true
    } else {
      setWrongWeight(false)
      item.wrongWeight = false
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#E5E5E5',
        borderRadius: '10px',
        padding: '10px 3px',
        margin: '10px 0',
      }}
    >
      <IonRow>
        <IonCol>
          <IonText style={{ fontWeight: 500 }}>
            {index}. {item.sku} - {item.description}
          </IonText>
        </IonCol>
        <IonCol className='ion-text-right'>
          <IonIcon
            size='large'
            onClick={removeCheckedItem}
            icon={closeCircleSharp}
            color='danger'
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-left'>
          <IonText style={{ fontWeight: 600 }}>
            KL Max:{'\u00A0'}
            {isExists(item.maxWeightAllow) ? `${item.maxWeightAllow}g` : 'N/A'}
          </IonText>
        </IonCol>
        <IonCol className='ion-text-right'>
          <IonText style={{ fontWeight: 600 }}>
            KL Min:{'\u00A0'}
            {isExists(item.minWeightAllow) ? `${item.minWeightAllow}g` : 'N/A'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow style={{ display: 'flex', alignItems: 'center' }}>
        <IonCol size='6' className='ion-text-left'>
          <IonText style={{ fontWeight: 600 }}>KL thực tế:</IonText>
        </IonCol>
        <IonCol
          size='6'
          className='ion-text-right'
          style={{ borderBottom: '1px solid black' }}
        >
          <IonInput
            onIonChange={() => onChangeQuantity(item)}
            placeholder='Nhập khối lượng'
            ref={inputRef}
            type='number'
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol className='ion-text-left'>
          <IonText
            style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
          >
            Check SKU:{'\u00A0'}
            {checkSKU ? (
              <IonBadge color='success'> 👍 OK</IonBadge>
            ) : (
              <IonBadge color='danger'> 👎 Sai</IonBadge>
            )}
          </IonText>
        </IonCol>
        <IonCol className='ion-text-right'>
          <IonText
            style={{
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Check KL:{'\u00A0'}
            {wrongWeight === null ? (
              <IonBadge color='dark'> N/A</IonBadge>
            ) : wrongWeight === true ? (
              <IonBadge color='danger'> 👎 Sai</IonBadge>
            ) : (
              <IonBadge color='success'> 👍 OK</IonBadge>
            )}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  )
}

export default CheckItem
