import { IonList } from '@ionic/react'
import { map } from 'lodash'
import { useHistory } from 'react-router'
import { IBuyingEntry } from '../model/buyingEntries'
import BuyingEntryItem from './BuyingEntryItem'

type TProps = {
  buyingEntries: IBuyingEntry[]
}

const BuyingEntryList = ({ buyingEntries }: TProps) => {
  const history = useHistory()
  function redirectBuyingEntryDetail(buyingEntry: IBuyingEntry) {
    history.push(`/buying-entries/confirm-buying-entries/${buyingEntry._id}`)
  }
  return (
    <>
      <IonList>
        {map(buyingEntries, (buyingEntry) => (
          <BuyingEntryItem
            key={buyingEntry._id}
            buyingEntry={buyingEntry}
            redirectBuyingEntryDetail={redirectBuyingEntryDetail}
          />
        ))}
      </IonList>
    </>
  )
}

export default BuyingEntryList
