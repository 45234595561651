import apiClient from '../libs/api-service'
const RestockReportAPI = {
  getAll: async (params: any) => {
    const restockReports = await apiClient.get('/api/restock-reports', {
      params,
    })
    return restockReports
  },
  fetchById: async (restockReportId: string) => {
    const restockReport = await apiClient.get(
      '/api/restock-reports/' + restockReportId
    )
    return restockReport
  },
  countMobile: async (
    restockReportId: string,
    productId: string,
    quantity: number
  ) => {
    const {
      item,
      restockReport,
    } = await apiClient.put(
      `/api/restock-reports/${restockReportId}/count-mobile/${productId}`,
      { quantity }
    )
    return { item, restockReport }
  },
  resolve: async (restockReportId: string) => {
    const restockReport = await apiClient.put(
      `/api/restock-reports/${restockReportId}/resolve`
    )
    return restockReport
  },
}

export default RestockReportAPI
