import { map } from 'lodash'
import { IonList } from '@ionic/react'

import { IProductLocation } from '../model/productLocation'
import ProductLocationItem from './ProductLocationItem'
import SearchProductLocation from '../../../components/SearchProductLocation'
import { useState, useContext } from 'react'
import ProductLocationAPI from '../../../api/productLocationAPI'
import { AppContext } from '../../../context'
import useNotify from '../../../hook/useNotify'

type TProps = {
  productLocations: IProductLocation[]
}

const ProductLocationList = ({ productLocations }: TProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const notify = useNotify()
  const [currentProduct, setCurrentProduct] = useState<IProductLocation>()

  const { productLocationStore } = useContext(AppContext)

  const { setIsLoading, updateProductLocation } = productLocationStore

  const onDismissModal = () => {
    setIsLoading(false)
    setIsShowModal(false)
  }

  const onChangeLocation = async (code: string) => {
    try {
      setIsLoading(true)
      const productWithNewLocation = await ProductLocationAPI.updateProductLocation(
        currentProduct!._id,
        code
      )
      updateProductLocation(productWithNewLocation)
      onDismissModal()
    } catch (error) {
      return notify.errorFromServer(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onShowModal = () => {
    setIsShowModal(true)
  }

  const onClickEdit = (product: IProductLocation) => {
    setCurrentProduct(product)
    onShowModal()
  }

  return (
    <>
      <IonList>
        {map(productLocations, (product) => (
          <ProductLocationItem
            key={product._id}
            productLocation={product}
            onClickEdit={onClickEdit}
          />
        ))}
      </IonList>
      <SearchProductLocation
        show={isShowModal}
        onDismiss={onDismissModal}
        product={currentProduct}
        onSelect={onChangeLocation}
      />
    </>
  )
}

export default ProductLocationList
