import { action, makeObservable, observable } from 'mobx'
import { RootStore } from '../../../models/root-store'
import { ICurrentShift } from '../model/currentShift'
import moment from 'moment'
export class CurrentShiftStore {
  rootStore: RootStore
  currentShift: ICurrentShift | null
  isLoading: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    const _currentShift = localStorage.getItem('currentShift')

    if (
      _currentShift &&
      moment().isSame(JSON.parse(_currentShift).date, 'day')
    ) {
      this.currentShift = JSON.parse(_currentShift)
    } else {
      this.currentShift = null
    }

    this.isLoading = false

    makeObservable(this, {
      currentShift: observable,
      isLoading: observable,
      setCurrentShift: action,
      getCurrentShift: action,
    })
  }

  setIsLoading = (status: boolean) => {
    this.isLoading = status
  }

  setCurrentShift = (currentShift: ICurrentShift) => {
    this.currentShift = currentShift
    localStorage.setItem('currentShift', JSON.stringify(currentShift))
  }
  removeCurrentShift = () => {
    this.currentShift = null
    localStorage.removeItem('currentShift')
  }

  getCurrentShift = () => {
    return this.currentShift
  }
}
