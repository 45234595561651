import { ToastProvider } from '@agney/ir-toast'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import './App.css'
import BarScanner from './components/BarScanner'
import Home from './components/Home'
import Logout from './components/Logout'
import Menu from './components/Menu'
import PrivateRoute from './components/PrivateRoute'
import { TabMenu } from './components/Tab'
import ChooseCurrentShift from './features/CurrentShift/page/ChooseCurrentShift'
import FeedbackView from './pages/FeedbackView'
import LoginView from './pages/LoginView'
import Storing from './pages/Storing'
import BuyingEntries from './pages/BuyingEntries'
/* Theme variables */
import './theme/variables.css'

const App = observer(() => {
  return (
    <IonApp>
      <ToastProvider>
        <IonReactRouter>
          <Menu />
          <IonRouterOutlet id='main'>
            <Route exact path='/login' component={LoginView} />
            <Route exact path='/feedback' component={FeedbackView} />
            <PrivateRoute
              exact
              path='/choose-current-shift'
              component={ChooseCurrentShift}
            />
            <PrivateRoute exact path='/logout' component={Logout} />
            <PrivateRoute path='/restock-reports' component={TabMenu} />
            <PrivateRoute path='/storing' component={Storing} />
            <PrivateRoute path='/buying-entries' component={BuyingEntries} />
            <PrivateRoute exact path='/home' component={Home} />
            <PrivateRoute exact path='/bar-scanner' component={BarScanner} />
            <Redirect exact from='/' to='/home' />
          </IonRouterOutlet>
        </IonReactRouter>
      </ToastProvider>
    </IonApp>
  )
})

export default App
